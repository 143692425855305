import React, {useState, useEffect} from 'react';
import InputWithLabel from '../Form/Input/InputWithLabel';
import api from '../../api';

const EditServicesPopup = ({show, onClose, onSave, vendorId}) => {
    const [formData, setFormData] = useState({
        services: [] // Изначально пустой список услуг
    });

    useEffect(() => {
        // Загружаем услуги конкретного вендора
        const fetchVendorData = async () => {
            try {
                const vendorServicesResponse = await api.get(`/vendor/${vendorId}/service-subtypes/`);  // Услуги вендора
                setFormData({
                    services: vendorServicesResponse.data || []  // Устанавливаем услуги вендора
                });
            } catch (error) {
                console.error('Error fetching vendor service subtypes:', error);
            }
        };

        if (vendorId) {
            fetchVendorData();  // Загружаем данные, если есть ID вендора
        }
    }, [vendorId]);

    const handleServiceChange = (index, e) => {
        const { name, value } = e.target;
        const updatedServices = formData.services.map((service, i) => {
            if (i === index) {
                return { ...service, [name]: value };  // Обновляем name и service_type
            }
            return service;
        });

        setFormData((prevData) => ({
            ...prevData,
            services: updatedServices
        }));
    };

    const handleAddServiceSelect = () => {
        setFormData((prevData) => ({
            ...prevData,
            services: [...prevData.services, {subtype_id: '', name: '', service_type: formData.services[0]?.service_type || ''}] // Добавляем новую услугу с пустыми данными и корректным service_type
        }));
    };

    const handleDeleteService = (index) => {
        const updatedServices = formData.services.filter((_, i) => i !== index);

        setFormData((prevData) => ({
            ...prevData,
            services: updatedServices
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const finalData = formData.services.map(service => ({
            subtype_id: service.subtype_id || null,  // Передаем id если есть, иначе null
            name: service.name,  // Передаем name, чтобы его можно было обновить
            service_type: service.service_type || null  // Передаем service_type только если он есть
        }));
        console.log("Submitting services data:", finalData);  // Логируем данные перед сохранением
        onSave(finalData); // Передача данных обратно
    };

    if (!show) return null;

    return (
        <div className="modal visible" id="edit-services">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                <button onClick={onClose} className="button no-style close js--close-modal">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleSubmit}>
                        <h2>Edit Services</h2>
                        {formData.services.map((service, index) => (
                            <React.Fragment key={index}>
                                <InputWithLabel
                                    name="name"
                                    value={service.name}  // Отображаем и редактируем name
                                    onChange={(e) => handleServiceChange(index, e)}
                                    label="Service Name"
                                    showDelete={true}
                                    onDelete={() => handleDeleteService(index)}
                                />
                            </React.Fragment>
                        ))}

                        <button
                            className="underline no-style"
                            type="button"
                            onClick={handleAddServiceSelect}
                        >
                            Add New Service
                        </button>

                        <button className="button button-black" type="submit">Save Services</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditServicesPopup;
