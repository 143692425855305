import React, { useState, useCallback, useEffect } from 'react';
import InputWithLabel from "../Form/Input/InputWithLabel";
import TextareaWithLabel from "../Form/Input/TextareaWithLabel";
import api from '../../api';

export default function AdvCampaignPopup({ show, showSendOkPopup, closeAdvCampaignPopup }) {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        number: '',
        url: '',
        details: ''
    });
    const [template, setTemplate] = useState(null);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) {
            newErrors.name = 'Name is required';
        }

        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email address is invalid';
        }

        if (!formData.number) {
            newErrors.number = 'Number is required';
        } else if (!/^\d{10,15}$/.test(formData.number)) {
            newErrors.number = 'Number is invalid';
        }

        if (!formData.details) {
            newErrors.details = 'Details are required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const fetchTemplate = async (templateId) => {
        try {
            const response = await api.get(`/email-template/${templateId}/`);
            setTemplate(response.data);
        } catch (error) {
            console.error('Error fetching template:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        if (!template) {
            console.error('Template not loaded');
            return;
        }

        const emailData = {
            subject: template.subject_template.replace('{{name}}', formData.name),
            message: template.message_template
                .replace('{{name}}', formData.name)
                .replace('{{email}}', formData.email)
                .replace('{{number}}', formData.number)
                .replace('{{url}}', formData.url)
                .replace('{{details}}', formData.details.replace(/\n/g, '<br>')), // Замена \n на <br>
            recipient: template.recipient,
            isHtml: true // Указываем, что сообщение будет в формате HTML
        };

        try {
            const response = await api.post('/send-email/', emailData);
            if (response.status === 200) {
                showSendOkPopup();
            } else {
                console.error('Failed to send email', response.data);
            }
        } catch (error) {
            console.error('Error sending email', error);
        }
    };

    const handleClose = useCallback(() => {
        closeAdvCampaignPopup();
    }, [closeAdvCampaignPopup]);

    useEffect(() => {
        const templateId = 2;  // Замените на нужный ID шаблона
        fetchTemplate(templateId);
    }, []);

    return (
        <>
            {show && (
                <div className="modal visible">
                    <div className="modal__overlay" onClick={handleClose}></div>
                    <div className="modal__content">
                        <button onClick={handleClose} className="button no-style close js--close-modal">
                            <img src="/assets/icon--close-CN6QlgAA.svg" alt="" />
                        </button>
                        <div className="modal__form">
                            <form className="content" onSubmit={handleSubmit}>
                                <h2>Advertising Campaign</h2>
                                <InputWithLabel
                                    name='name'
                                    label='Name'
                                    value={formData.name}
                                    onChange={handleChange}
                                    error={errors.name}
                                />
                                <InputWithLabel
                                    name='email'
                                    label='E-mail'
                                    value={formData.email}
                                    onChange={handleChange}
                                    type='email'
                                    error={errors.email}
                                />
                                <InputWithLabel
                                    name='number'
                                    label='Number'
                                    value={formData.number}
                                    onChange={handleChange}
                                    type='tel'
                                    error={errors.number}
                                />
                                <InputWithLabel
                                    name='url'
                                    label='URL'
                                    value={formData.url}
                                    onChange={handleChange}
                                    type='text'
                                    error={errors.url}
                                />
                                <span>About campaign</span>
                                <TextareaWithLabel
                                    name='details'
                                    label='Details'
                                    value={formData.details}
                                    onChange={handleChange}
                                    error={errors.details}
                                />
                                <button type='submit' className="button button-black">Send</button>
                                <span>We will contact you soon and clarify the details</span>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
