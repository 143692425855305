import React, { useCallback, useState } from 'react';
import { getZipCodeFromCoordinates } from './geocode';

const GeolocationButton = ({ onLocationObtained }) => {
    const [userLocation, setUserLocation] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const handleNearMeToggle = useCallback(() => {
        setIsChecked(!isChecked);

        if (isChecked) {
            // Очищаем значения при отключении
            setUserLocation('');
            setZipCode('');
            console.log('Geolocation deactivated');
            onLocationObtained('', '', false); // Возвращаем состояние кнопки
        } else {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                console.log(`User location: ${latitude}, ${longitude}`);
                setUserLocation({ latitude, longitude });
                const zip = await getZipCodeFromCoordinates(latitude, longitude);
                setZipCode(zip);
                console.log(`User ZIP code: ${zip}`);
                onLocationObtained(zip, { latitude, longitude }, true); // Возвращаем состояние кнопки
            }, (error) => {
                console.error('Error getting geolocation:', error);
            });
        }
    }, [isChecked, onLocationObtained]);

    return (
        <div className='search__radius'>
            <span>{userLocation ? `ZIP: ${zipCode}` : 'Near me'}</span>
            <label onClick={handleNearMeToggle} className={isChecked ? 'toggle checked' : 'toggle'}>
                <div className='toggle__container'>
                    <div className='toggle__circle'></div>
                </div>
            </label>
        </div>
    );
};

export default GeolocationButton;
