import React, { useCallback, useState, useEffect } from 'react';

export default function InputSelect({
    name,
    value,
    onChange,
    label,
    options = [], // Массив опций для селекта
    showDelete,
    onDelete,
    error,
    initialProficiency = 'Fluent' // Пропс для передачи начального состояния proficiency
}) {
    const handlerChange = useCallback((e) => {
        onChange(e);
    }, [onChange]);

    // Инициализируем состояние proficiency значением из пропса
    const [proficiency, setProficiency] = useState(initialProficiency);

    // При изменении initialProficiency обновляем состояние
    useEffect(() => {
        setProficiency(initialProficiency);
    }, [initialProficiency]);

    // Переключение между "Native" и "Fluent"
    const handleToggle = () => {
        const newProficiency = proficiency === 'Fluent' ? 'Native' : 'Fluent';
        setProficiency(newProficiency);
        // Передаем выбранное значение proficiency обратно в родительский компонент
        onChange({ target: { name: 'proficiency_level', value: newProficiency } });
    };

    return (
        <>
            <label className="input-label">
                {/* Выпадающий список для выбора языка */}
                <select
                    name={name}
                    value={value}
                    onChange={handlerChange}
                    className={"input " + (value ? 'filled' : '')}
                    style={{ paddingTop: 0 }}
                >
                    <option value="" disabled>{label}</option>
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                {showDelete && (
                    <div className="help js--show-info" data-text="Add Icon">
                        <button
                            type="button"
                            className="button no-style"
                            onClick={onDelete}
                            style={{ backgroundColor: 'transparent' }}
                        >
                            <img src="/assets/icon--delete-gray.svg" alt="Delete" />
                        </button>
                    </div>
                )}
                {error && <span className="error-message">{error}</span>}
            </label>

            {/* Переключатель уровня владения (Native/Fluent) */}
            <div className='search__radius'>
                <span>Native</span>
                <label onClick={handleToggle} className={proficiency === 'Native' ? 'toggle checked' : 'toggle'}>
                    <div
                        className='toggle__container'
                        style={{
                            background: proficiency === 'Native' ? '#2C4157' : '#E0E0E0'  // Задаем фон в зависимости от состояния
                        }}
                    >
                        <div className='toggle__circle'></div>
                    </div>
                </label>
            </div>
        </>
    );
}
