import React, {useCallback, useEffect, useState, useContext, useRef} from 'react';
import LawContentEmpty from './IndexConent/LawContentEmpty';
import ShowMoreLawFirms from './ShowMoreLawCompanies';
import {SearchContext} from '../context/SearchContext';
import {RiveDemo} from '../utils/animateExample';
import './IndexConent/indexContent.css';
import {TextAnimator} from "../utils/text-animator";
import AnswerResult from './AnswerResult';

const SearchedLawFirms = ({onShowPopup, onShowMore}) => {
    const [animator, setAnimator] = useState(null);
    const [hoverDescription, setHoverDescription] = useState(false);
    const {
        lawFirmResults,
        searchSended,
        loading,
        loadingMore,
        changeCompaniesForTitle,
        lawFirmTotalResults,
        error
    } = useContext(SearchContext);
    const descriptionRef = useRef(null);


    useEffect(() => {
        changeCompaniesForTitle('Law Firms');
    }, [changeCompaniesForTitle]);

    useEffect(() => {
        if (descriptionRef.current) {
            const animatorInstance = new TextAnimator(descriptionRef.current);
            setAnimator(animatorInstance);
        }
    }, [lawFirmResults]);

    const animateText = useCallback(() => {
        if (animator) {
            animator.reset();
            animator.animate();
        }
    }, [animator]);

    const handleIntersection = useCallback((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                animateText();
            }
        });
    }, [animateText]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1
        });
        if (descriptionRef.current) {
            observer.observe(descriptionRef.current);
        }
        return () => {
            if (descriptionRef.current) {
                observer.unobserve(descriptionRef.current);
            }
        };
    }, [handleIntersection]);

    const handlerHover = useCallback(() => {
        setHoverDescription(true);
        if (!hoverDescription) {
            animateText();
        }
    }, [hoverDescription, animateText]);

    if (error && error.status === 404) {
        // Обрабатываем ошибку 404 и отображаем пустой компонент
        return <LawContentEmpty/>;
    }


    if (loading && !loadingMore && lawFirmResults.length === 0) {
        return (
            <main className='emptyContent'>
                <div className='content'>
                    <div className='icons'>
                        <RiveDemo/>
                    </div>
                    <span
                        onMouseOver={handlerHover}
                        onMouseLeave={() => setHoverDescription(false)}
                        className='title empty-animate'
                    >
                        {'Loading...'}
                    </span>
                </div>
            </main>
        );
    }

    return (
        <div className="searched-law-firms">
            {searchSended ? (
                lawFirmResults.length === 0 ? (
                    <AnswerResult/>
                ) : (
                    <>
                        <div className="accordeon__el">
                            <div className={"accordeon__header"}>
                                <div className="header-content">
                                    <div className="container">
                                        <div className="justify"
                                             style={{justifyContent: 'flex-start', alignItems: 'center'}}>
                                            <div className='law-firms'>

                                                <h4>
                                                    AI
                                                </h4>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;
                                            <div className='icons'>
                                                <RiveDemo/>
                                            </div>
                                            &nbsp;&nbsp;
                                            <div className='law-firms'>
                                                <h4
                                                    onMouseOver={handlerHover}
                                                    onMouseLeave={() => setHoverDescription(false)}
                                                    ref={descriptionRef}
                                                >
                                                    Searched Law Firms:
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ShowMoreLawFirms onShowPopup={onShowPopup} onShowMore={onShowMore}/>
                    </>
                )
            ) : (
                <LawContentEmpty/>
            )}
        </div>
    );
};

export default SearchedLawFirms;