import './indexContent.css';
import {RiveDemo} from "../../utils/animateExample";
import React, {useCallback, useEffect, useRef, useState} from "react";
import api from '../../api';
import {TextAnimator} from "../../utils/text-animator";

export default function IndexContentEmpty() {
    const [animator, setAnimator] = useState(null);
    const [hoverDescription, setHoverDescription] = useState(false);
    const [listTitle, setListTitle] = useState('');
    const [listDescription, setListDescription] = useState('');
    const titleRef = useRef(null);

    useEffect(() => {
        api.get('/first-home-page-description/')
            .then(response => {
                setListTitle(response.data.list_title);
                setListDescription(response.data.list_description);
            })
            .catch(error => {
                console.error('Error fetching description:', error);
            });
    }, []);

    useEffect(() => {
        if (titleRef.current) {
            console.log("Element for animation:", titleRef.current);
            const animatorInstance = new TextAnimator(titleRef.current);
            setAnimator(animatorInstance);
        }
    }, [listTitle]);

    const animateText = useCallback(() => {
        if (animator) {
            console.log("Animating text");
            animator.reset();
            animator.animate();
        }
    }, [animator]);

    const handleIntersection = useCallback((entries) => {
        entries.forEach(entry => {
            console.log("Intersection entry:", entry);
            if (entry.isIntersecting) {
                console.log("Element is intersecting");
                animateText();
            }
        });
    }, [animateText]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1
        });
        if (titleRef.current) {
            console.log("Observing element:", titleRef.current);
            observer.observe(titleRef.current);
        }
        return () => {
            if (titleRef.current) {
                observer.unobserve(titleRef.current);
            }
        };
    }, [handleIntersection]);

    const handlerHover = useCallback(() => {
        setHoverDescription(true);
        if (!hoverDescription) {
            animateText();
        }
    }, [hoverDescription, animateText]);

    return (
        <div className='content'>
            <div className='icons'>
                <RiveDemo/>
            </div>
            <span
                onMouseOver={handlerHover}
                onMouseLeave={() => setHoverDescription(false)}
                className='title centered-left-span'
                ref={titleRef}
            >
                {listTitle || 'AI search continuously learns and helps find accurate answers to your queries faster'}
            </span>
            <p className='description'>
                {listDescription || 'AI search continuously learns and helps find accurate answers to your queries faster'}
            </p>
        </div>
    );
}
