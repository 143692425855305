import React from 'react';
import InputWithLabel from "../Form/Input/InputWithLabel";
import TextareaWitLabel from "../Form/Input/TextareaWithLabel";
import {useCallback} from "react";

export default function SendOkPopup ({show, closePopup}) {

    const handleClick = useCallback(() => {
        closePopup();
    })

    return (
        <>
            <div className="modal visible ">
                <div className="modal__overlay"></div>
                <div className="modal__content">
                    <button onClick={handleClick} className="button no-style close js--close-modal">
                        <img src="/assets/icon--close-CN6QlgAA.svg" alt=""/>
                    </button>
                    <div className="modal__form large">
                        <form className="content">
                            <img src="/assets/icon--sendok.svg" alt=""/>
                            <h2>Request has been sent</h2>
                            <span>We will contact you soon and clarify the details</span>
                            <button type='button' onClick={handleClick} className="button button-black">Okay</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}