import React, {useState} from 'react';
import InputWithLabel from '../Form/Input/InputWithLabel';

const EditPreviousWorkplacesPopup = ({show, onClose, onSave, initialData}) => {
    const [formData, setFormData] = useState(() => ({
        previous_working_places: initialData?.previous_working_places?.map(place => ({
            ...place,
            name: place.name || '',
            position: place.position || '',
            start_date: place.start_date || '',
            end_date: place.end_date || ''
        })) || []
    }));

    const handleWorkplaceChange = (index, name, value) => {
        const updatedWorkplaces = formData.previous_working_places.map((place, i) => {
            if (i === index) {
                return {...place, [name]: value};
            }
            return place;
        });

        setFormData((prevData) => ({
            ...prevData,
            previous_working_places: updatedWorkplaces
        }));
    };

    const handleAddWorkplace = () => {
        setFormData((prevData) => ({
            ...prevData,
            previous_working_places: [...prevData.previous_working_places, {
                name: '',
                position: '',
                start_date: '',
                end_date: ''
            }]
        }));
    };

    const handleDeleteWorkplace = (index) => {
        const updatedWorkplaces = formData.previous_working_places.filter((_, i) => i !== index);
        setFormData((prevData) => ({
            ...prevData,
            previous_working_places: updatedWorkplaces
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validWorkplaces = formData.previous_working_places.filter(place => place.name.trim() && place.position.trim());
        if (validWorkplaces.length) {
            await onSave({previous_working_places: validWorkplaces});
        } else {
            console.error("No valid workplaces to save");
        }
    };

    if (!show) return null;

    return (
        <div className="modal visible" id="edit-previous-workplaces">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                <button onClick={onClose} className="button no-style close js--close-modal">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleSubmit}>
                        <h2>Edit Previous Workplaces</h2>
                        {formData.previous_working_places.map((place, index) => (
                            <React.Fragment key={index}>
                                <InputWithLabel
                                    name="name"
                                    value={place.name}
                                    onChange={(e) => handleWorkplaceChange(index, 'name', e.target.value)}
                                    label="Company Name"
                                />
                                <InputWithLabel
                                    name="position"
                                    value={place.position}
                                    onChange={(e) => handleWorkplaceChange(index, 'position', e.target.value)}
                                    label="Position"
                                />
                                <InputWithLabel
                                    name="start_date"
                                    value={place.start_date}
                                    onChange={(e) => handleWorkplaceChange(index, 'start_date', e.target.value)}
                                    label="Start Date"
                                    type="date"
                                />
                                <InputWithLabel
                                    name="end_date"
                                    value={place.end_date}
                                    onChange={(e) => handleWorkplaceChange(index, 'end_date', e.target.value)}
                                    label="End Date"
                                    type="date"
                                />
                                <button
                                    className="button no-style"
                                    type="button"
                                    onClick={() => handleDeleteWorkplace(index)}
                                >
                                    Delete
                                </button>
                            </React.Fragment>
                        ))}
                        <button
                            className="button button-border"
                            type="button"
                            onClick={handleAddWorkplace}
                        >
                            Add New Workplace
                        </button>
                        <button className="button button-black" type="submit">Save Workplaces</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditPreviousWorkplacesPopup;
