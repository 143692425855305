import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import InputWithLabel from "../Form/Input/InputWithLabel";
import api from '../../api'; // Импортируйте созданный api
import './SignIn.css'; // Добавьте свой CSS файл

const SignIn = ({}) => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false); // Добавим состояние загрузки
    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            navigate('/profile'); // Redirect to profile if user is authenticated
        }
    }, [navigate]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setLoading(true);

        if (!formData.email || !formData.password) {
            setErrors({
                email: !formData.email ? 'Email is required' : '',
                password: !formData.password ? 'Password is required' : ''
            });
            setLoading(false);
            return;
        }

        try {
            // Выводим данные перед отправкой
            console.log('Sending data:', {
                username: formData.email, // Изменяем ключ с email на username
                password: formData.password
            });

            const response = await api.post('/token/', {
                username: formData.email, // Изменяем ключ с email на username
                password: formData.password
            });

            if (response.status === 200) {
                localStorage.setItem('access_token', response.data.access);
                localStorage.setItem('refresh_token', response.data.refresh);
                navigate('/profile');
                window.location.reload(); // Перезагружаем страницу после перенаправления
            } else {
                setErrors({
                    email: response.data.detail || 'Invalid credentials',
                    password: ' '
                });
            }
        } catch (error) {
            console.log('Error response:', error.response);
            setErrors({email: 'Something went wrong. Please try again later.'});
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="main-form-container">
            <div
                className="main-form__img"
                style={{
                    backgroundColor: '#fff',
                    backgroundImage: "url('/assets/sign-in-image.png')",
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 50%',
                    backgroundSize: 'cover',
                }}
            ></div>

            <div className="main-form__right">
                <form className="main-form__content" onSubmit={handleSubmit}>
                    <div className="scroll-wrap">
                        <h2>Let's get started</h2>
                        <InputWithLabel
                            label='E-mail'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                            type='email'
                            error={errors.email}
                        />
                        <InputWithLabel
                            label='Password'
                            name='password'
                            value={formData.password}
                            onChange={handleChange}
                            type='password'
                            error={errors.password}
                        />
                        <div className="checkbox-container">
                            <label className="label-checkbox">
                                <input type="checkbox"/>
                                <div className="psevdocheckbox"></div>
                            </label>
                            <span className="text-left">By clicking you confirm you are accept our <a
                                href="/terms-of-service" className="checkbox__link"
                                target="_blank">Terms of Service</a> and <a href="/privacy-policy"
                                                                            className="checkbox__link" target="_blank">Privacy Policy</a>.</span>
                        </div>

                        <button className="button button-black" type="submit" disabled={loading}>
                            {loading ? 'Loading...' : 'Continue'}
                        </button>
                        <button className="button button-border">
                            <img src="/assets/Apple.svg" alt="Apple Icon" style={{marginRight: '8px'}}/>
                            Continue with Apple
                        </button>
                        <button className="button button-border">
                            <img src="/assets/Google.svg" alt="Google Icon" style={{marginRight: '8px'}}/>
                            Continue with Google
                        </button>

                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignIn;
