import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import VendorContentEmpty from './VendorContentEmpty';
import ShowMoreVendors from './ShowMoreVendors';
import { SearchContext } from '../../context/SearchContext';
import { RiveDemo } from '../../utils/animateExample';
import '../IndexConent/indexContent.css';
import { TextAnimator } from "../../utils/text-animator";
import AnswerResult from '../AnswerResult';

const SearchedVendors = ({ onShowPopup, onShowMore }) => {
    const [animator, setAnimator] = useState(null);
    const [hoverDescription, setHoverDescription] = useState(false);
    const descriptionRef = useRef(null);
    const { vendorResults = [], searchSended, loading, loadingMore, error, changeCompaniesForTitle } = useContext(SearchContext);

    useEffect(() => {
        changeCompaniesForTitle('Vendors');
    }, [changeCompaniesForTitle]);

    useEffect(() => {
        if (descriptionRef.current) {
            const animatorInstance = new TextAnimator(descriptionRef.current);
            setAnimator(animatorInstance);
        }
    }, [vendorResults]);

    const animateText = useCallback(() => {
        if (animator) {
            animator.reset();
            animator.animate();
        }
    }, [animator]);

    const handleIntersection = useCallback((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                animateText();
            }
        });
    }, [animateText]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1
        });
        if (descriptionRef.current) {
            observer.observe(descriptionRef.current);
        }
        return () => {
            if (descriptionRef.current) {
                observer.unobserve(descriptionRef.current);
            }
        };
    }, [handleIntersection]);

    const handlerHover = useCallback(() => {
        setHoverDescription(true);
        if (!hoverDescription) {
            animateText();
        }
    }, [hoverDescription, animateText]);

    if (error && error.status === 404) {
        // Обрабатываем ошибку 404 и отображаем пустой компонент
        return <VendorContentEmpty />;
    }

    if (loading && !loadingMore && vendorResults.length === 0) {
        return (
            <main className='emptyContent'>
                <div className='content'>
                    <div className='icons'>
                        <RiveDemo />
                    </div>
                    <span onMouseOver={handlerHover} onMouseLeave={() => setHoverDescription(false)}
                          className='title empty-animate' ref={descriptionRef}>
                        {'Loading...'}
                    </span>
                </div>
            </main>
        );
    }

    return (
        <div className="searched-vendors">
            {searchSended ? (
                vendorResults.length === 0 ? (
                    <AnswerResult />
                ) : (
                    <>
                        <div className="accordeon__el">
                            <div className={"accordeon__header"}>
                                <div className="header-content">
                                    <div className="container">
                                        <div className="justify"
                                             style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <div className='vendors'>
                                                <h4>
                                                    AI
                                                </h4>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;
                                            <div className='icons'>
                                                <RiveDemo />
                                            </div>
                                            &nbsp;&nbsp;
                                            <div className='vendors'>
                                                <h4
                                                    onMouseOver={handlerHover}
                                                    onMouseLeave={() => setHoverDescription(false)}
                                                    ref={descriptionRef}
                                                >
                                                    Searched Vendors:
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ShowMoreVendors onShowPopup={onShowPopup} onShowMore={onShowMore} />
                    </>
                )
            ) : (
                <VendorContentEmpty />
            )}
        </div>
    );
};

export default SearchedVendors;
