import React, {useCallback} from 'react';

export default function InputWithLabel({
                                           name,
                                           value,
                                           onChange,
                                           type = 'text',
                                           error,
                                           label,
                                           min,
                                           max,
                                           step,
                                           showInfo,
                                           showDelete,
                                           onDelete
                                       }) {
    const handlerChange = useCallback((e) => {
        onChange(e);
    }, [onChange]);

    return (
        <label className="input-label">
            <input
                name={name}
                onChange={handlerChange}
                className={"input " + (value ? 'filled' : '')}
                type={type}
                value={value}
                min={type === 'number' ? min : undefined}  // Добавляем min, если тип number
                max={type === 'number' ? max : undefined}  // Добавляем max, если тип number
                step={type === 'number' ? step : undefined}  // Добавляем step, если тип number
            />
            <span className="placeholder">{label}</span>

            {showInfo && (
                <div className="help js--show-info" data-text="Add Icon">
                    {showInfo && <img src="/assets/icon--info-8nUm4QuK.svg" alt={'Info'}/>}
                </div>
            )}
            {showDelete && (
                <div className="help js--show-info" data-text="Add Icon">

                    <button
                        type="button"
                        className="button no-style"
                        onClick={onDelete}
                        style={{
                            // marginLeft: '8px',
                            // cursor: 'pointer', // Оставляем курсор указателем, чтобы была видна кликабельность
                            backgroundColor: 'transparent',
                            // border: 'none',
                            // padding: '0',
                            // outline: 'none',
                        }}
                    >
                        <img src="/assets/icon--delete-gray.svg" alt="Delete"/>
                        {/*<img src="/assets/icon--delete.svg" alt="Delete"/>*/}
                    </button>
                </div>
            )}

            {error && <span className="error-message">{error}</span>}
        </label>
    );
}