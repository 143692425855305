import React, { useState, useEffect } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import './style.css';

const MultiSelectInColumnWithLabel = ({ label, options, onChange, placeholder }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        if (onChange) {
            onChange(selectedOptions);
        }
    }, [selectedOptions, onChange]);

    return (
        <div className="column">
            <label className="label">{label}</label>
            <MultiSelect
                value={selectedOptions}
                options={options}
                onChange={(e) => setSelectedOptions(e.value)}
                optionLabel="name"
                display="chip"
                filter
                className=""
                placeholder={placeholder}
            />
        </div>
    );
};

export default MultiSelectInColumnWithLabel;
