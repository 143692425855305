import React, { useRef, useEffect } from 'react';
import './Tooltip.css';

export default function Tooltip({ content }) {
    const tooltipRef = useRef(null);

    useEffect(() => {
        if (tooltipRef.current) {
            const tooltipContent = tooltipRef.current.querySelector('.tooltip__content');
            const tooltipText = tooltipRef.current.querySelector('.tooltip__text');
            if (tooltipText && tooltipContent) {
                // Убираем фиксированную ширину и даем элементу подстраиваться под текст
                tooltipContent.style.width = `${tooltipText.offsetWidth + 20}px`; // 20px - небольшой отступ
            }
        }
    }, [content]);

    return (
        <div className='tooltip' ref={tooltipRef}>
            <div className='tooltip__icon'>
                <img src='/assets/icon--tooltip.svg' alt='tooltip icon' />
            </div>
            <div className='tooltip__content'>
                <div className='tooltip__text'>
                    {content}
                </div>
            </div>
        </div>
    );
}
