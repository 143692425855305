import React from 'react';
import Tooltip from './Tooltip';

export default function ZipCodes({ city = "Unknown City", zipCodes = [] }) {
    return (
        <div className='zip'>
            <span><Tooltip content={`ZIP codes: ${zipCodes.join('  •  ')}`} /></span>
            {/*<span> • {zipCodes.length}</span>*/}

        </div>
    );
}
