import React, {useEffect, useState} from 'react';
import api from "../api";

const LawCompanyWithImage = ({company, onShowPopup, styleClass}) => {
    // if (!company) {
    //     console.error('LawCompanyWithImage received no company data');
    //     return null;
    // }
    const [isFavorite, setIsFavorite] = useState(false);

    useEffect(() => {
        const checkFavoriteStatus = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const config = {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                };
                const response = await api.get(`/check-favorite-lawcompany/${company.id}/`, config);
                setIsFavorite(response.data.is_favorite);
            } catch (error) {
                console.error('Error checking favorite status:', error);
            }
        };

        checkFavoriteStatus();
    }, [company]);

    const handleAddToFavorites = async () => {
        try {
            const response = await api.post('/add-favorite-lawcompany/', {company_id: company.id});

            if (response.status === 200 || response.status === 201) {
                setIsFavorite(true);
            }
        } catch (error) {
            console.error('Error adding to favorites:', error);
        }
    };

    const handleRemoveFromFavorites = async () => {
        try {
            const response = await api.post('/remove-favorite-lawcompany/', {company_id: company.id});

            if (response.status === 200) {
                setIsFavorite(false);
            }
        } catch (error) {
            console.error('Error removing from favorites:', error);
        }
    };

    const handleToggleFavorites = () => {
        if (isFavorite) {
            handleRemoveFromFavorites();
        } else {
            handleAddToFavorites();
        }
    };


    const truncateDescription = (desc, length) => {
        if (desc && desc.length > length) {
            return desc.substring(0, length) + '...';
        }
        return desc;
    };

    const baseUrl = process.env.REACT_APP_BASE_URL;


    const getLogoUrl = (logo) => {
        console.log('Base URL:', baseUrl);
        console.log('Logo before modification:', logo);

        // Проверяем, является ли logo полным URL
        if (logo && !logo.startsWith('http://') && !logo.startsWith('https://')) {
            const modifiedLogo = `${baseUrl}${logo}`;
            console.log('Modified Logo:', modifiedLogo);
            return modifiedLogo;
        }

        // Если logo уже содержит полный URL, возвращаем его без изменений
        return logo;
    };


    const formatPhoneNumber = (phoneNumber) => {
        let cleaned = ('' + phoneNumber).replace(/\D/g, '');
        if (!cleaned.startsWith('1')) {
            cleaned = '1' + cleaned;
        }
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
        return phoneNumber;
    };

    const renderFreeConsultation = () => {
        if (company && company.free_consultation) {
            return (
                <span className="vendor__day">FREE CONSULTATION</span>
            );
        }
        return null;
    };

    const formattedAddress = company.address
        ? `${company.address.street_address}, ${company.address.city.name}, ${company.address.state.name}`
        : 'Address not available';

    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(formattedAddress)}`;
    const appleMapsUrl = `http://maps.apple.com/?q=${encodeURIComponent(formattedAddress)}`;

    return (
        <div className={`vendor ${styleClass}`} style={{
                background: styleClass === 'vendor-day'
                    ? `linear-gradient(rgba(44, 65, 87, 0.7), rgba(44, 65, 87, 0.7)), url(${company.background_image})`
                    : ``
            }}>
            <div className="container">
                <div className="justify">
                    {styleClass === 'vendor-day' && company.logo && (
                        <div className="vendor__logo js--show-popup" onClick={() => onShowPopup(company.id, 'company')}>
                            <picture>
                                <source type="image/webp"
                                        srcSet={`${getLogoUrl(company.logo)} 1x, ${getLogoUrl(company.logo)} 2x`}/>
                                <source type="image/jpg"
                                        srcSet={`${getLogoUrl(company.logo)} 1x, ${getLogoUrl(company.logo)} 2x`}/>
                                <img srcSet={`${getLogoUrl(company.logo)} 1x, ${getLogoUrl(company.logo)} 2x`}
                                     alt={company.name}/>
                            </picture>

                        </div>
                    )}
                    <div className="vendor__data js--show-popup" onClick={() => onShowPopup(company.id, 'company')}>
                        <div className="vendor__header">
                            <h4 className="vendor__name">{company.name}</h4>
                            {styleClass === 'vendor-verified' && (
                                <div className="verified">
                                    <img src="assets/icon--verified-CxOX3a18.svg" alt="Verified"/>
                                </div>
                            )}
                            <button
                                className={`favorites ${isFavorite ? 'added' : ''}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleToggleFavorites(company.id);
                                }}
                            >
                                <svg>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M1.58733 3.10072C3.70393 0.940385 7.13582 0.940385 9.25246 3.10072L10.0323 3.89665L10.7475 3.16683C12.8641 1.0065 16.296 1.0065 18.4127 3.16683C20.5293 5.32717 20.5292 8.82998 18.4127 10.9904L10.4695 19.0901C10.4418 19.1184 10.5003 19.068 10.4695 19.0901C10.2068 19.2849 9.79324 19.2991 9.5566 19.0576L1.58757 10.9239C-0.52903 8.76353 -0.529268 5.26111 1.58733 3.10072Z"></path>
                                </svg>
                            </button>
                            {styleClass === 'vendor-day' && renderFreeConsultation()}
                        </div>
                        <span className="vendor__attorney">
                            {company.attorneys ? company.attorneys.length : 0} Attorneys On Staff
                            {company.foundation_year && (
                                <span> • Founded In {company.foundation_year}</span>
                            )}
                        </span>
                        <p className="vendor__description">{truncateDescription(company.description, 150)}</p>
                    </div>
                    <div className="vendor__contacts">
                        <div className="row">
                            <a className="vendor__link" href={googleMapsUrl} target="_blank"
                               rel="noopener noreferrer">{formattedAddress}</a>
                            <span className="vendor__label">Address</span>
                        </div>
                        <div className="row">
                            <a className="vendor__link"
                               href={`tel:${company.phone_number}`}>{formatPhoneNumber(company.phone_number)}</a>
                            <span className="vendor__label">Phone</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LawCompanyWithImage;