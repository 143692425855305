import React, { useContext, useEffect } from 'react';
import CombinedPopularLists from "./CombinedPopularLists";
import { SearchContext } from "../context/SearchContext";
import IndexContentEmpty from "./IndexConent/IndexContentEmpty";
import api from '../api';

const MainContent = ({ onShowPopup }) => {
    const {
        searchSended,
        searchSource,  // Получаем searchSource из контекста
        setSearchSended,  // Функция для сброса searchSended
        currentPage,
        setSearchResults,
        specialization,
        problem,
        cities,
        states,
        zipCode,
        language,
        freeConsultation,
        foundationYears,
        showAdvanced
    } = useContext(SearchContext);

    // Добавляем useEffect для проверки searchSource
    useEffect(() => {
        if (searchSource === 'form') {
            console.log('Search initiated from form');
            // Ваша логика здесь для обработки поиска, вызванного с формы
        } else {
            // Если источник поиска не форма, сбрасываем searchSended
            if (searchSended) {
                setSearchSended(false);
                console.log('SearchSended сброшен, так как источник не форма');
            }
        }
    }, [searchSource, searchSended, setSearchSended]);

    useEffect(() => {
        // Получаем элемент main
        const mainElement = document.querySelector('main');

        // Проверяем наличие элемента перед обращением к его classList
        if (mainElement) {
            if (showAdvanced) {
                mainElement.classList.add('no-scroll');
            } else {
                mainElement.classList.remove('no-scroll');
            }

            return () => {
                // Проверка перед удалением класса при размонтировании
                if (mainElement) {
                    mainElement.classList.remove('no-scroll');
                }
            };
        }
    }, [showAdvanced]);

    const handleShowMore = async (page) => {
        console.log('handleShowMore called, page:', page);
        try {
            const requestData = {
                specialization,
                problem,
                cities: cities.map(city => city.city_id),
                states: states.map(state => state.state_id),
                zip_code: zipCode,
                language,
                free_consultation: freeConsultation,
                foundation_years: foundationYears,
                page
            };
            console.log('Sending show more request with data:', requestData);

            const response = await api.post(`/get-filtered-specialization/`, requestData);
            const { results, count, total_pages, current_page } = response.data;
            console.log('Response from handleShowMore:', response.data);
            setSearchResults({ results, count, page: current_page });
        } catch (error) {
            console.error('Error fetching more companies:', error);
        }
    };

    return (
        <>
            {!searchSended ?
                <main className='emptyContent'>
                    <IndexContentEmpty />
                </main>
                :
                <main>
                    <div className="search-result">
                        <CombinedPopularLists onShowPopup={onShowPopup} onShowMore={handleShowMore} />
                    </div>
                </main>
            }
        </>
    );
};

export default MainContent;
