import React, {useCallback, useContext, useEffect, useState} from "react";
import MultiSelectWithDependentCities from '../MultiSelects/MultiSelectWithDependentCities';
import MultiSelectWithDependentZipCodes from '../MultiSelects/MultiSelectWithDependentZipCodes';
import MultiSelectInColumnWithLabel from '../MultiSelects/MultiSelectInColumnWithLabel';
import MultiSelectFoundationYear from '../MultiSelects/MultiSelectFoundationYear';
import MultiSelectPlatformsAndContactMethods from '../MultiSelects/MultiSelectPlatformsAndContactMethods';
import MultiSelectWithDependentServiceSubtypes from '../MultiSelects/MultiSelectWithDependentServiceSubtypes';
import {SearchContext} from '../../context/SearchContext';
import api from '../../api';
import fetchCityByIP from '../FetchCityByIP';
import './pageLawAndVendorsList.css';
import GeolocationButton from '../GeolocationButton';
import SearchedVendors from '../Vendors/SearchedVendors';

const PageVendors = ({onShowPopup, onSearch}) => {
    const {
        setVendorResults,
        changeSearchSended,
        setVendorPage,
        setSpecialization,
        setCities,
        setStates,
        setZipCode,
        setLanguage,
        setFreeConsultation,
        setFoundationYears,
        setLoading,
        zipCode,
        suggestion,
    } = useContext(SearchContext);

    const [showAdvanced, setShowAdvanced] = useState(false);
    const [specializations, setSpecializations] = useState([]);
    const [selectedZipCodes, setSelectedZipCodes] = useState([]);
    const [selectedState, setSelectedState] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [selectedServiceSubtype, setSelectedServiceSubtype] = useState([]);
    const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);
    const [selectedFoundationYears, setSelectedFoundationYears] = useState([]);
    const [selectedContactMethods, setSelectedContactMethods] = useState([]);
    const [selectedSocialMedia, setSelectedSocialMedia] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState([]);
    const [userLocation, setUserLocation] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [isConsultationCheck, setIsConsultationCheck] = useState(false);
    const [isAwardsCheck, setIsAwardsCheck] = useState(false);
    const [isZipcodeProvided, setIsZipcodeProvided] = useState(false);

    const [prevRequestData, setPrevRequestData] = useState(null);

    useEffect(() => {
        const fetchSpecializations = async () => {
            try {
                const response = await api.get('/specializations-full/');
                setSpecializations(response.data.map(spec => ({name: spec.name, code: spec.name})));
            } catch (error) {
                console.error('Error fetching specializations:', error);
            }
        };

        const handleContactMethodsChange = (selectedOptions) => {
            setSelectedContactMethods(selectedOptions.contactMethods || []);
            setSelectedSocialMedia(selectedOptions.socialMedia || []);
        };

        const fetchLanguages = async () => {
            try {
                const response = await api.get('/languages/');
                const uniqueLanguages = Array.from(new Set(response.data.map(lang => lang.name)))
                    .map(name => response.data.find(lang => lang.name === name));
                setLanguages(uniqueLanguages);
                console.log('Fetched unique languages:', uniqueLanguages);
            } catch (error) {
                console.error('Error fetching languages:', error);
            }
        };

        fetchSpecializations();
        fetchLanguages();
    }, []);

    useEffect(() => {
        const getCityFromIP = async () => {
            const city = await fetchCityByIP();
            if (city) {
                setSelectedCity([city]);
            }
        };

        if (!userLocation && !zipCode) {
            getCityFromIP();
        }
    }, [userLocation, zipCode]);

    const toggleAdvancedSearch = () => {
        setShowAdvanced(!showAdvanced);
    };

    const handleSearch = async (page = 1) => {
        let requestData = {};

        const isEmptyForm =
            !isZipcodeProvided &&
            (!selectedCity || selectedCity.length === 0) &&
            (!selectedState || selectedState.length === 0) &&
            (!selectedZipCodes || selectedZipCodes.length === 0) &&
            (!selectedServiceSubtype || selectedServiceSubtype.length === 0) &&
            (!selectedServiceTypes || selectedServiceTypes.length === 0) &&
            (!selectedFoundationYears || selectedFoundationYears.length === 0) &&
            (!selectedContactMethods || selectedContactMethods.length === 0) &&
            (!selectedSocialMedia || selectedSocialMedia.length === 0) &&
            (!selectedLanguage || selectedLanguage.length === 0) &&
            !isConsultationCheck &&
            !isAwardsCheck;

        if (isEmptyForm) {
            console.log('Форма пуста. Поиск не будет выполнен.');
            return;
        }

        if (isZipcodeProvided) {
            requestData = {
                zip_codes: [{code: {name: zipCode, code: zipCode}}],
                service_types: Array.isArray(selectedServiceTypes) ? selectedServiceTypes.map(type => ({
                    id: type.service_type_id,
                    name: type.service_type_name
                })) : [],
                service_subtypes: Array.isArray(selectedServiceSubtype) ? selectedServiceSubtype.map(subtype => subtype.name) : [],
                foundation_years: selectedFoundationYears.map(year => ({year: year})),
                contact_methods: selectedContactMethods.map(method => ({name: method})),
                social_media: Array.isArray(selectedSocialMedia) ? selectedSocialMedia.map(media => ({platform: media})) : [],
                languages: Array.isArray(selectedLanguage) ? selectedLanguage.map(lang => ({name: lang})) : [],
                workers: isConsultationCheck,
                has_awards: isAwardsCheck,
                page
            };
        } else {
            requestData = {
                cities: selectedCity.map(city => ({id: city.city_id, name: city.name})),
                service_types: Array.isArray(selectedServiceTypes) ? selectedServiceTypes.map(type => ({
                    id: type.service_type_id,
                    name: type.service_type_name
                })) : [],
                service_subtypes: Array.isArray(selectedServiceSubtype) ? selectedServiceSubtype.map(subtype => subtype.name) : [],
                states: selectedState.map(state => ({state_id: state.state_id, name: state.name})),
                zip_codes: selectedZipCodes.map(zip => ({code: zip})),
                foundation_years: selectedFoundationYears.map(year => ({year: year})),
                contact_methods: selectedContactMethods.map(method => ({name: method})),
                social_media: Array.isArray(selectedSocialMedia) ? selectedSocialMedia.map(media => ({platform: media})) : [],
                languages: Array.isArray(selectedLanguage) ? selectedLanguage.map(lang => ({name: lang})) : [],
                workers: isConsultationCheck,
                has_awards: isAwardsCheck,
                page
            };
        }
        console.log('Request Data:', requestData);

        if (JSON.stringify(requestData) === JSON.stringify(prevRequestData)) {
            console.log('Данные запроса не изменились. Пропуск отправки запроса.');
            return;
        }

        setLoading(true);
        try {
            changeSearchSended();
            console.log('Отправка запроса с данными:', requestData);
            const response = await api.post('/vendors-searching/', requestData);
            console.log('Ответ от сервера:', response.data);

            const aiSuggestion = response.data.suggestion || "AI suggests you contact a vendor specializing";

            if (onSearch) {
                console.log('Вызов onSearch с данными:', response.data);
                onSearch({results: response.data.results, count: response.data.count, page, suggestion: aiSuggestion});
                setVendorPage(page);
                setSpecialization(selectedServiceSubtype);
                setCities(selectedCity);
                setStates(selectedState);
                setZipCode(selectedZipCodes);
                setFreeConsultation(isConsultationCheck);
                setFoundationYears(selectedFoundationYears);
            }

            setVendorResults({
                results: response.data.results,
                count: response.data.count,
                page,
                suggestion: aiSuggestion
            });

            setPrevRequestData(requestData);

        } catch (error) {
            console.error('Ошибка при получении результатов:', error);
            const aiSuggestion = (error.response && error.response.data && error.response.data.suggestion) || "AI suggests you contact a vendor specializing";
            if (onSearch) {
                onSearch({results: [], count: 0, page: 1, suggestion: aiSuggestion});
            }
        } finally {
            setLoading(false);
        }
    };

    const clearLocation = () => {
        console.log('Clearing location and ZIP code due to user action');
        setUserLocation(null);
        setZipCode('');
        setIsZipcodeProvided(false);
    };

    const handleLocationObtained = (zip, location, isEnabled) => {
        console.log('ZIP Code from geolocation:', zip);
        console.log('Location from geolocation:', location);
        console.log('Is Near Me enabled:', isEnabled);

        if (isEnabled) {
            setUserLocation(location);
            setZipCode(zip);
            if (zip) {
                setIsZipcodeProvided(true);
            }
        } else {
            clearLocation();
        }
    };

    const handleServiceSubtypeChange = ({selectedServiceSubtypes, selectedServiceTypes}) => {
        setSelectedServiceSubtype(selectedServiceSubtypes);
        setSelectedServiceTypes(selectedServiceTypes);
        console.log("Selected service types:", selectedServiceTypes);
        console.log("Selected service subtypes:", selectedServiceSubtypes);
    };

    const handleConsultationClick = useCallback(() => {
        setIsConsultationCheck(!isConsultationCheck);
    }, [isConsultationCheck]);

    const handleAwardsClick = useCallback(() => {
        setIsAwardsCheck(!isAwardsCheck);
    }, [isAwardsCheck]);

    const onCityStateChange = (cities, states) => {
        setSelectedCity(cities);
        setSelectedState(states);
    };

    const onZipCodeChange = (zipCodes) => {
        setSelectedZipCodes(zipCodes);
    };

    const onContactMethodsChange = (contactMethods) => {
        setSelectedContactMethods(contactMethods);
    };

    const onSocialMediaChange = (socialMedia) => {
        setSelectedSocialMedia(socialMedia);
    };

    const onLanguageChange = (languages) => {
        setSelectedLanguage(languages);
    };

    return (
        <div className='page-list'>
            <div className='header__search'>
                <div className='container'>
                    <div className='search__advanced has-button show'>
                        <div className='grid-container'>
                            <MultiSelectWithDependentCities
                                label="City"
                                onChange={onCityStateChange}
                                states={selectedState}
                                placeholder="Select city"
                                isZipcodeProvided={isZipcodeProvided}
                                disabled={isZipcodeProvided}
                            />
                            <MultiSelectWithDependentZipCodes
                                label="ZIP Codes"
                                onChange={onZipCodeChange}
                                cities={selectedCity}
                                placeholder="Select zip codes"
                                isZipcodeProvided={isZipcodeProvided}
                                disabled={isZipcodeProvided}
                            />
                            <MultiSelectWithDependentServiceSubtypes
                                label="Service Subtypes"
                                onChange={handleServiceSubtypeChange}
                                serviceType={selectedServiceSubtype}
                                placeholder="Select service subtypes"
                            />
                            <MultiSelectInColumnWithLabel
                                label="Languages"
                                options={languages.map(lang => ({name: lang.name, code: lang.name}))}
                                onChange={onLanguageChange}
                                placeholder="Select languages"
                            />
                            <MultiSelectFoundationYear
                                label="Foundation Year"
                                onChange={setSelectedFoundationYears}
                            />
                            <MultiSelectPlatformsAndContactMethods
                                label="Contact Methods"
                                onChange={(selectedOptions) => {
                                    console.log('Selected Contact Methods and Social Media:', selectedOptions);
                                    setSelectedContactMethods(selectedOptions.contactMethods);
                                    setSelectedSocialMedia(selectedOptions.socialMedia);
                                }}
                            />
                        </div>
                        <div className='header__search__buttons'>
                            <div className='column desktop'>
                                <label className="label">Workers</label>
                                <label onClick={handleConsultationClick}
                                       className={isConsultationCheck ? 'consultations-checkbox checked' : 'consultations-checkbox'}>
                                    <span>All</span>
                                    <span>Yes</span>
                                </label>
                            </div>
                            <div className='column mobile'>
                                <label className="label">&nbsp;</label>
                                <div className='search__radius'>
                                    <span>Workers</span>
                                    <label
                                        className={`toggle ${isConsultationCheck ? 'checked' : ''}`}
                                        onClick={handleConsultationClick}
                                    >
                                        <div className='toggle__container'>
                                            <div className='toggle__circle'></div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className='column column-button'>
                                <label className="label">&nbsp;</label>
                                <label>
                                    <button className='button button-black' onClick={() => handleSearch(1)}>Find
                                        Vendor
                                    </button>
                                </label>
                            </div>
                            <div className='column desktop'>
                                <label className="label">Awards</label>
                                <label onClick={handleAwardsClick}
                                       className={isAwardsCheck ? 'consultations-checkbox checked' : 'consultations-checkbox'}>
                                    <span>All</span>
                                    <span>Yes</span>
                                </label>
                            </div>
                            <div className='column mobile'>
                                <label className="label">&nbsp;</label>
                                <div className='search__radius'>
                                    <span>Awards</span>
                                    <label
                                        className={`toggle ${isAwardsCheck ? 'checked' : ''}`}
                                        onClick={handleAwardsClick}
                                    >
                                        <div className='toggle__container'>
                                            <div className='toggle__circle'></div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className='column'>
                                <label className="label">&nbsp;</label>
                                <GeolocationButton onLocationObtained={handleLocationObtained}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SearchedVendors onShowPopup={onShowPopup} onShowMore={handleSearch}/>
        </div>
    );
};

export default PageVendors;
