// geocode.js

export const getZipCodeFromCoordinates = async (latitude, longitude) => {
    const apiKey = '2022efef26ab4355a5d5a528c7101f60'; // Замените на ваш API-ключ
    const url = `https://api.geoapify.com/v1/geocode/reverse?lat=${latitude}&lon=${longitude}&apiKey=${apiKey}`;

    try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.features && data.features.length > 0) {
            const addressComponents = data.features[0].properties;
            const zipCode = addressComponents.postcode;
            return zipCode;
        } else {
            console.error('Error fetching geocode data:', data.status);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

export const getCityFromZipCode = async (zipCode) => {
    const apiKey = '2022efef26ab4355a5d5a528c7101f60'; // Замените на ваш API-ключ
    const url = `https://api.geoapify.com/v1/geocode/search?text=${zipCode}&apiKey=${apiKey}`;

    try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.features && data.features.length > 0) {
            const addressComponents = data.features[0].properties;
            const city = addressComponents.city;
            return city;
        } else {
            console.error('Error fetching geocode data:', data.status);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};
