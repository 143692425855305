import React, { useContext, useState, useRef } from 'react';
import VariousVendorCards from './VariousVendorCards';
import { SearchContext } from '../../context/SearchContext';

const ShowMoreVendors = ({ onShowPopup }) => {
    const { vendorResults, vendorPage, vendorTotalResults, fetchMoreVendors, loadingMore } = useContext(SearchContext);
    const [isLoading, setIsLoading] = useState(false);
    const scrollPositionRef = useRef(0);

    const saveScrollPosition = () => {
        scrollPositionRef.current = window.scrollY || document.documentElement.scrollTop;
        console.log('Сохраненная позиция скролла:', scrollPositionRef.current);
    };

    const restoreScrollPosition = () => {
        console.log('Восстановление позиции скролла до:', scrollPositionRef.current);
        window.scrollTo(0, scrollPositionRef.current);
        console.log('Текущая позиция скролла после восстановления:', window.scrollY || document.documentElement.scrollTop);
    };

    const loadMoreVendors = async () => {
        if (isLoading || loadingMore) return;
        saveScrollPosition();
        setIsLoading(true);
        await fetchMoreVendors(vendorPage + 1);
        setIsLoading(false);
        restoreScrollPosition();
    };

    return (
        <div className="accordeon__el">
            <div className="accordeon__content">
                <VariousVendorCards vendors={vendorResults} onShowPopup={onShowPopup} />
                {vendorResults.length < vendorTotalResults && (
                    <li className="show-all">
                        <div className="container">
                            <button onClick={loadMoreVendors} disabled={isLoading || loadingMore}>
                                {loadingMore ? 'Loading...' : `Show More Vendors • ${vendorTotalResults - vendorResults.length}`}
                            </button>
                        </div>
                    </li>
                )}
            </div>
        </div>
    );
};

export default ShowMoreVendors;
