// Import the TextSplitter class for handling text splitting.
import {TextSplitter} from './textSplitter.js';

// Define the single symbol to be used in the animation.
const blackSquare = '■';

// Defines a class to create hover effects on text.
export class TextAnimator {
    constructor(textElement) {
        if (!textElement || !(textElement instanceof HTMLElement)) {
            throw new Error('Invalid text element provided.');
        }

        this.textElement = textElement;
        this.splitText();
    }

    splitText() {
        this.splitter = new TextSplitter(this.textElement, {
            splitTypeTypes: 'words, chars'
        });

        this.originalChars = this.splitter.getChars().map(char => char.innerHTML);
    }

    animate() {
        this.reset(); // Останавливаем предыдущую анимацию перед началом новой

        const chars = this.splitter.getChars();

        chars.forEach((char, position) => {
            let initialHTML = char.innerHTML;
            let repeatCount = 0;

            window.gsap.fromTo(char, {
                    opacity: 0
                },
                {
                    duration: 0.03,
                    onStart: () => {
                        window.gsap.set(char, {'--opa': 1});
                    },
                    onComplete: () => {
                        window.gsap.set(char, {innerHTML: initialHTML, delay: 0.03})
                    },
                    repeat: 0,
                    onRepeat: () => {
                        repeatCount++;
                        if (repeatCount === 1) {
                            window.gsap.set(char, {'--opa': 0});
                        }
                    },
                    repeatRefresh: true,
                    repeatDelay: 0.03,
                    delay: (position + 1) * 0.07,
                    innerHTML: () => blackSquare,
                    opacity: 1
                });
        });
    }

    reset() {
        const chars = this.splitter.getChars();
        chars.forEach((char, index) => {
            window.gsap.killTweensOf(char);
            char.innerHTML = this.originalChars[index];
        });
    }
}

