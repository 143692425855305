import React, {useContext, useState, useRef} from 'react';
import VariousCompanyCards from './VariousCompanyCards';
import {SearchContext} from '../context/SearchContext';

const ShowMoreLawFirms = ({onShowPopup, onShowMore}) => {
    const {lawFirmResults, lawFirmPage, lawFirmTotalResults, loadingMore} = useContext(SearchContext);
    const [isLoading, setIsLoading] = useState(false);
    const scrollPositionRef = useRef(0);

    // Сохранение позиции скролла перед загрузкой новых данных
    const saveScrollPosition = () => {
        scrollPositionRef.current = window.scrollY || document.documentElement.scrollTop;
        console.log('Сохраненная позиция скролла:', scrollPositionRef.current); // Логируем сохраненную позицию
    };

    // Восстановление позиции скролла после загрузки новых данных
    const restoreScrollPosition = () => {
        console.log('Восстановление позиции скролла до:', scrollPositionRef.current); // Логируем позицию перед восстановлением
        window.scrollTo(0, scrollPositionRef.current);
        console.log('Текущая позиция скролла после восстановления:', window.scrollY || document.documentElement.scrollTop); // Логируем позицию после восстановления
    };

    const loadMoreCompanies = async () => {
        if (isLoading || loadingMore) return;
        saveScrollPosition();
        setIsLoading(true);
        await onShowMore(lawFirmPage + 1);
        setIsLoading(false);
        restoreScrollPosition();

    };

    return (
        <div className="accordeon__el">
            <div className="accordeon__content">
                <VariousCompanyCards lawCompanies={lawFirmResults} onShowPopup={onShowPopup}/>
                {lawFirmResults.length < lawFirmTotalResults && (
                    <li className="show-all">
                        <div className="container">
                            <button onClick={loadMoreCompanies} disabled={isLoading || loadingMore}>
                                {loadingMore ? 'Loading...' : `Show More`}
                            </button>
                        </div>
                    </li>
                )}
            </div>
        </div>
    );
};

export default ShowMoreLawFirms;
