import React, { useEffect, useState, useRef, useCallback } from "react";
import { RiveDemo } from "../utils/animateExample";
import { TextAnimator } from "../utils/text-animator";

const AnswerResult = () => {
    const [notFoundAnimator, setNotFoundAnimator] = useState(null);
    const [suggestionAnimator, setSuggestionAnimator] = useState(null);
    const [hoverDescription, setHoverDescription] = useState(false);
    const notFoundRef = useRef(null);
    const suggestionRef = useRef(null);

    useEffect(() => {
        if (notFoundRef.current) {
            const animatorInstance = new TextAnimator(notFoundRef.current);
            setNotFoundAnimator(animatorInstance);
        }
        if (suggestionRef.current) {
            const suggestionInstance = new TextAnimator(suggestionRef.current);
            setSuggestionAnimator(suggestionInstance);
        }
    }, []);

    const animateText = useCallback(() => {
        if (notFoundAnimator) {
            notFoundAnimator.reset();
            notFoundAnimator.animate();
        }
        if (suggestionAnimator) {
            suggestionAnimator.reset();
            suggestionAnimator.animate();
        }
    }, [notFoundAnimator, suggestionAnimator]);

    const handleIntersection = useCallback((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                animateText();
            }
        });
    }, [animateText]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1
        });
        if (notFoundRef.current) {
            observer.observe(notFoundRef.current);
        }
        if (suggestionRef.current) {
            observer.observe(suggestionRef.current);
        }
        return () => {
            if (notFoundRef.current) {
                observer.unobserve(notFoundRef.current);
            }
            if (suggestionRef.current) {
                observer.unobserve(suggestionRef.current);
            }
        };
    }, [handleIntersection]);

    const handlerHover = useCallback(() => {
        setHoverDescription(true);
        if (!hoverDescription) {
            animateText();
        }
    }, [hoverDescription, animateText]);

    return (
        <div className='no-result'>
            <div className='container'>
                <div className='no-result__content'>
                    <div className='no-result__icon'>
                        <RiveDemo />
                    </div>
                    <div className='no-result__text'>
                        <h2
                            className='description-animate'
                            onMouseOver={handlerHover}
                            onMouseLeave={() => setHoverDescription(false)}
                            ref={notFoundRef}
                        >
                            Nothing was found
                        </h2>
                        <p
                            className='description-animate'
                            onMouseOver={handlerHover}
                            onMouseLeave={() => setHoverDescription(false)}
                            ref={suggestionRef}
                        >
                            Try changing the query
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnswerResult;
