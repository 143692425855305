import React, { useContext, useState } from 'react';
import VariousCompanyCards from './VariousCompanyCards';
import { SearchContext } from '../context/SearchContext';

const PopularLawCompanies = ({ onShowPopup, onShowMore }) => {
    const { searchResults, currentPage, totalResults, fetchMoreCompanies, loadingMore } = useContext(SearchContext);
    const [isLoading, setIsLoading] = useState(false);

    const loadMoreCompanies = async () => {
        if (isLoading || loadingMore) return; // Проверяем оба состояния загрузки
        setIsLoading(true);
        await fetchMoreCompanies(currentPage + 1);
        setIsLoading(false);
    };

    return (
        <div className="accordeon__el">
            <div className="accordeon__content">
                <VariousCompanyCards lawCompanies={searchResults} onShowPopup={onShowPopup} />
                {searchResults.length < totalResults && (
                    <li className="show-all">
                        <div className="container">
                            <button onClick={loadMoreCompanies} disabled={isLoading || loadingMore}>
                                {loadingMore ? 'Loading...' : `Show More Law Companies • ${totalResults - searchResults.length}`}
                            </button>
                        </div>
                    </li>
                )}
            </div>
        </div>
    );
};

export default PopularLawCompanies;
