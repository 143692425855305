import React, { useState, useEffect } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import api from '../../api';
import './style.css';

const MultiSelectWithDependentServiceSubtypes = ({ label, onChange }) => {
    const [serviceTypes, setServiceTypes] = useState([]);
    const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);
    const [serviceSubtypes, setServiceSubtypes] = useState([]);
    const [selectedServiceSubtypes, setSelectedServiceSubtypes] = useState([]);

    // Fetch service types on component mount
    useEffect(() => {
        const fetchServiceTypes = async () => {
            try {
                const response = await api.get('/service-types/');
                setServiceTypes(response.data);
                console.log('Fetched service types:', response.data);
            } catch (error) {
                console.error('Error fetching service types:', error);
            }
        };

        fetchServiceTypes();
    }, []);

    // Fetch service subtypes whenever selected service types change
    useEffect(() => {
        const fetchServiceSubtypes = async () => {
            if (selectedServiceTypes.length === 0) {
                setServiceSubtypes([]);
                return;
            }

            try {
                const serviceTypeIds = selectedServiceTypes.map(serviceType => serviceType.service_type_id).join(',');
                const response = await api.get(`/service-subtypes-for-multiselect/?service_types=${serviceTypeIds}`);
                setServiceSubtypes(response.data);
                console.log('Fetched service subtypes:', response.data);
            } catch (error) {
                console.error('Error fetching service subtypes:', error);
            }
        };

        fetchServiceSubtypes();
    }, [selectedServiceTypes]);

    // Update selected service subtypes based on selected service types
    useEffect(() => {
        if (Array.isArray(selectedServiceSubtypes)) {
            const selectedServiceTypeIds = selectedServiceTypes.map(serviceType => serviceType.service_type_id);
            const filteredSelectedServiceSubtypes = selectedServiceSubtypes.filter(subtype =>
                selectedServiceTypeIds.includes(subtype.service_type.service_type_id)
            );

            if (JSON.stringify(filteredSelectedServiceSubtypes) !== JSON.stringify(selectedServiceSubtypes)) {
                setSelectedServiceSubtypes(filteredSelectedServiceSubtypes);
            }
        }
    }, [selectedServiceTypes]);

    // Notify parent component when selected service subtypes or types change
    useEffect(() => {
        if (onChange) {
            onChange({ selectedServiceSubtypes, selectedServiceTypes });
        }
    }, [selectedServiceSubtypes, selectedServiceTypes]);

    const handleServiceTypeChange = (e) => {
        const newSelectedServiceTypes = e.value;
        const newSelectedServiceTypeIds = newSelectedServiceTypes.map(type => type.service_type_id);

        if (Array.isArray(selectedServiceSubtypes)) {
            const filteredSelectedServiceSubtypes = selectedServiceSubtypes.filter(subtype =>
                newSelectedServiceTypeIds.includes(subtype.service_type.service_type_id)
            );

            setSelectedServiceSubtypes(filteredSelectedServiceSubtypes);
        }

        setSelectedServiceTypes(newSelectedServiceTypes);
    };

    const handleServiceSubtypeChange = (e) => {
        setSelectedServiceSubtypes(e.value);
    };

    return (
        <>
            <div className="column">
                <label className="label">Service Types</label>
                <MultiSelect
                    value={selectedServiceTypes}
                    options={serviceTypes}
                    onChange={handleServiceTypeChange}
                    optionLabel="service_type_name"
                    display="chip"
                    filter
                    className=""
                    placeholder="Select service types"
                />
            </div>
            <div className="column">
                <label className="label">Service Subtypes</label>
                <MultiSelect
                    value={selectedServiceSubtypes}
                    options={serviceSubtypes}
                    onChange={handleServiceSubtypeChange}
                    optionLabel="name"
                    display="chip"
                    filter
                    className=""
                    placeholder="Subtypes will appear when you select types"
                    disabled={selectedServiceTypes.length === 0}
                />
            </div>
        </>
    );
};

export default MultiSelectWithDependentServiceSubtypes;
