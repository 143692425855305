import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import MultiSelectWithDependentCities from '../../components/MultiSelects/MultiSelectWithDependentCities';
import MultiSelectWithDependentZipCodes from '../../components/MultiSelects/MultiSelectWithDependentZipCodes';
import MultiSelectInColumnWithLabel from '../../components/MultiSelects/MultiSelectInColumnWithLabel';
import MultiSelectFoundationYear from '../../components/MultiSelects/MultiSelectFoundationYear';
import MultiSelectContactMethods from '../../components/MultiSelects/MultiSelectContactMethods';
import MultiSelectSocialMedia from '../../components/MultiSelects/MultiSelectSocialMedia';
import {SearchContext} from '../../context/SearchContext';
import api from '../../api';
import fetchCityByIP from '../../components/FetchCityByIP';
import './pageLawAndVendorsList.css';
import GeolocationButton from '../../components/GeolocationButton';
import SearchedLawFirms from "../SearchedLawFirms";
import ZipCodes from "../Header/ZipCodes";

export default function PageLawFirms({onShowPopup, onSearch}) {
    const {
        setLawFirmResults,
        changeSearchSended,
        setLawFirmPage,
        setSpecialization,
        setCities,
        setStates,
        setZipCode,
        setLanguage,
        setFreeConsultation,
        setFoundationYears,
        setLoading,
        zipCode,
        suggestion
    } = useContext(SearchContext);

    const [showAdvanced, setShowAdvanced] = useState(false);
    const [specializations, setSpecializations] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [selectedZipCodes, setSelectedZipCodes] = useState([]);
    const [selectedState, setSelectedState] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [defaultCity, setDefaultCity] = useState(null);
    const [defaultCityName, setDefaultCityName] = useState(null);
    const [selectedSpecialization, setSelectedSpecialization] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState([]);
    const [selectedContactMethods, setSelectedContactMethods] = useState([]);
    const [selectedSocialMedia, setSelectedSocialMedia] = useState([]);
    const [userLocation, setUserLocation] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [isAllSpecializationActive, setIsAllSpecializationActive] = useState(true);
    const [isConsultationCheck, setIsConsultationCheck] = useState(false);
    const [selectedFoundationYears, setSelectedFoundationYears] = useState([]);
    const [isAwardsCheck, setIsAwardsCheck] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [isZipcodeProvided, setIsZipcodeProvided] = useState(false);

    const [prevRequestData, setPrevRequestData] = useState(null);

    const nothingFoundTitleRef = useRef(null);
    const nothingFoundTextRef = useRef(null);

    useEffect(() => {
        const fetchSpecializations = async () => {
            try {
                const response = await api.get('/specializations-full/');
                setSpecializations(response.data.map(spec => ({name: spec.name, code: spec.name})));
            } catch (error) {
                console.error('Error fetching specializations:', error);
            }
        };

        const fetchLanguages = async () => {
            try {
                const response = await api.get('/languages/');
                const uniqueLanguages = Array.from(new Set(response.data.map(lang => lang.name)))
                    .map(name => response.data.find(lang => lang.name === name));
                setLanguages(uniqueLanguages);
                console.log('Fetched unique languages:', uniqueLanguages);
            } catch (error) {
                console.error('Error fetching languages:', error);
            }
        };

        fetchSpecializations();
        fetchLanguages();
    }, []);

    useEffect(() => {
        const getCityFromIP = async () => {
            const city = await fetchCityByIP();
            if (city) {
                setDefaultCityName(city);
                setSelectedCity([city]);
            }
        };

        if (!userLocation && !zipCode) {
            getCityFromIP();
        }
    }, [userLocation, zipCode]);


    const toggleAdvancedSearch = () => {
        setShowAdvanced(!showAdvanced);
    };

    const handleSearch = async (page = 1) => {

        let requestData = {};

        // Проверяем, есть ли заполненные поля в форме
        const isEmptyForm =
            !isZipcodeProvided &&
            selectedCity.length === 0 &&
            selectedState.length === 0 &&
            selectedZipCodes.length === 0 &&
            selectedSpecialization.length === 0 &&
            selectedLanguage.length === 0 &&
            selectedContactMethods.length === 0 &&
            selectedSocialMedia.length === 0 &&
            selectedFoundationYears.length === 0 &&
            !isConsultationCheck &&
            !isAwardsCheck;

        if (isEmptyForm) {
            console.log('Форма пуста. Поиск не будет выполнен.');
            return;
        }

        if (isZipcodeProvided) {
            // Если включен Near Me, используем только ZIP из геолокации
            requestData = {
                zip_codes: [{code: {name: zipCode, code: zipCode}}], // Преобразуем формат zipCode
                specialization: selectedSpecialization.map(spec => spec.name),
                languages: selectedLanguage.map(lang => ({name: lang})),
                contact_methods: selectedContactMethods.map(method => ({name: method})),
                social_media: selectedSocialMedia.map(media => ({platform: media})),
                foundation_years: selectedFoundationYears.map(year => ({year: year})),
                free_consultation: isConsultationCheck,
                has_awards: isAwardsCheck,
                page
            };
        } else {
            // Если Near Me выключен, отправляем все данные
            requestData = {
                cities: selectedCity.map(city => ({id: city.city_id, name: city.name})),
                specialization: selectedSpecialization.map(spec => spec.name),
                states: selectedState.map(state => ({state_id: state.state_id, name: state.name})),
                zip_codes: selectedZipCodes.map(zip => ({code: zip})),
                languages: selectedLanguage.map(lang => ({name: lang})),
                contact_methods: selectedContactMethods.map(method => ({name: method})),
                social_media: selectedSocialMedia.map(media => ({platform: media})),
                foundation_years: selectedFoundationYears.map(year => ({year: year})),
                free_consultation: isConsultationCheck,
                has_awards: isAwardsCheck,
                page
            };
        }
        console.log("Отправка запроса с данными:", requestData); // Логирование запроса перед отправкой

        if (JSON.stringify(requestData) === JSON.stringify(prevRequestData)) {
            console.log('Данные запроса не изменились. Пропуск отправки запроса.');
            return;
        }

        setLoading(true);
        try {
            changeSearchSended();
            console.log('Отправка запроса с данными:', requestData);
            const response = await api.post('/law-firms/', requestData);
            console.log('Ответ от сервера:', response.data);

            const aiSuggestion = response.data.suggestion || "AI suggests you contact a company specializing";

            if (onSearch) {
                console.log('Вызов onSearch с данными:', response.data);
                onSearch({results: response.data.results, count: response.data.count, page, suggestion: aiSuggestion});
                setLawFirmPage(page);
                setSpecialization(selectedSpecialization);
                setCities(selectedCity);
                setStates(selectedState);
                setZipCode(selectedZipCodes);
                setLanguage(selectedLanguage);
                setFreeConsultation(isConsultationCheck);
                setFoundationYears(selectedFoundationYears);
            }

            // Обновляем результаты поиска law firms в контексте
            setLawFirmResults({
                results: response.data.results,
                count: response.data.count,
                page,
                suggestion: aiSuggestion
            });

            // Обновляем предыдущее состояние запроса
            setPrevRequestData(requestData);

        } catch (error) {
            console.error('Ошибка при получении результатов:', error);
            const aiSuggestion = (error.response && error.response.data && error.response.data.suggestion) || "AI suggests you contact a company specializing";
            if (onSearch) {
                onSearch({results: [], count: 0, page: 1, suggestion: aiSuggestion});
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log('Is ZIP Code provided:', isZipcodeProvided);
    }, [isZipcodeProvided]);

    const clearLocation = () => {
        // Сброс только по действию пользователя
        console.log('Clearing location and ZIP code due to user action');
        setUserLocation(null);
        setZipCode('');
        setIsZipcodeProvided(false);
    };


    const handleLocationObtained = (zip, location, isEnabled) => {
        console.log('ZIP Code from geolocation:', zip);
        console.log('Location from geolocation:', location);
        console.log('Is Near Me enabled:', isEnabled);

        if (isEnabled) {
            setUserLocation(location);
            setZipCode(zip);
            if (zip) {
                setIsZipcodeProvided(true);
            }
        } else {
            // Сброс выполняется только вручную (например, если пользователь выключил Near Me через интерфейс)
            clearLocation();
        }
    };


    const handleSpecializationChange = (spec) => {
        setSelectedSpecialization(spec);
        setIsAllSpecializationActive(spec.length === 0);
        console.log("Selected specializations:", spec); // Добавьте логирование для проверки
    };


    const handleConsultationClick = useCallback(() => {
        setIsConsultationCheck(!isConsultationCheck);
    }, [isConsultationCheck]);

    const handleAwardsClick = useCallback(() => {
        setIsAwardsCheck(!isAwardsCheck);
    }, [isAwardsCheck]);

    const onCityStateChange = (cities, states) => {
        setSelectedCity(cities);
        setSelectedState(states);
    };

    const onZipCodeChange = (zipCodes) => {
        setSelectedZipCodes(zipCodes);
    };

    const onContactMethodsChange = (contactMethods) => {
        console.log('Received contact methods:', contactMethods); // Логируем полученные контактные методы
        setSelectedContactMethods(contactMethods);
    };

    const onSocialMediaChange = (socialMedia) => {
        setSelectedSocialMedia(socialMedia);
    };

    const handleZipcodeFromSearch = (zipcode) => {
        setZipCode([zipcode]);
        setUserLocation({city: selectedCity[0], zipcode}); // Обновляем userLocation с zipcode
    };

    return (
        <div className='page-list'>
            <div className='header__search'>
                <div className='container'>
                    <div className='search__advanced has-button show'>
                        <div className='grid-container'>
                            <MultiSelectWithDependentCities
                                label="City"
                                onChange={onCityStateChange}
                                states={selectedState}
                                placeholder="Select city"
                                isZipcodeProvided={isZipcodeProvided} // Передаем состояние для отображения сообщения
                                disabled={isZipcodeProvided} // Отключаем, если ZIP код был предоставлен
                            />
                            <MultiSelectWithDependentZipCodes
                                label="ZIP Codes"
                                onChange={onZipCodeChange}
                                cities={selectedCity}
                                placeholder="Select zip codes"
                                isZipcodeProvided={isZipcodeProvided}
                                disabled={isZipcodeProvided} // Отключаем, если ZIP код был предоставлен
                            />
                            <MultiSelectInColumnWithLabel
                                label="Specializations"
                                options={specializations}
                                onChange={handleSpecializationChange}
                                placeholder=""
                            />
                            <MultiSelectInColumnWithLabel
                                label="Languages"
                                options={languages.map(lang => ({name: lang.name, code: lang.name}))}
                                onChange={setSelectedLanguage}
                                placeholder=""
                            />
                            <MultiSelectFoundationYear
                                label="Foundation Year"
                                onChange={setSelectedFoundationYears}
                            />
                            <MultiSelectContactMethods
                                label="Contact Methods"
                                onChange={onContactMethodsChange}
                            />
                            <MultiSelectSocialMedia
                                label="Social Media"
                                onChange={onSocialMediaChange}
                            />
                        </div>
                        <div className='header__search__buttons'>
                            <div className='column desktop'>
                                <label className="label">Consultation</label>
                                <label onClick={handleConsultationClick}
                                       className={isConsultationCheck ? 'consultations-checkbox checked' : 'consultations-checkbox'}>
                                    <span>All</span>
                                    <span>Free</span>
                                </label>
                            </div>
                            <div className='column mobile'>
                                <label className="label">&nbsp;{/*Нужен пустой для отступа сверху*/}</label>
                                <div className='search__radius'>
                                    <span>Free Consultation</span>
                                    <label
                                        className={`toggle ${isConsultationCheck ? 'checked' : ''}`}
                                        onClick={handleConsultationClick}
                                    >
                                        <div className='toggle__container'>
                                            <div className='toggle__circle'></div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className='column column-button'>
                                <label className="label">&nbsp;{/*Нужен пустой для отступа сверху*/}</label>
                                <label>
                                    <button className='button button-black' onClick={() => handleSearch(1)}>Find Law
                                        Firm
                                    </button>
                                </label>
                            </div>
                            <div className='column desktop'>
                                <label className="label">Awards</label>
                                <label onClick={handleAwardsClick}
                                       className={isAwardsCheck ? 'consultations-checkbox checked' : 'consultations-checkbox'}>
                                    <span>No</span>
                                    <span>Yes</span>
                                </label>
                            </div>
                            <div className='column mobile'>
                                <label className="label">&nbsp;{/*Нужен пустой для отступа сверху*/}</label>
                                <div className='search__radius'>
                                    <span>Awards</span>
                                    <label
                                        className={`toggle ${isAwardsCheck ? 'checked' : ''}`}
                                        onClick={handleAwardsClick}
                                    >
                                        <div className='toggle__container'>
                                            <div className='toggle__circle'></div>
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div className='column'>
                                <label className="label">&nbsp;{/*Нужен пустой для отступа сверху*/}</label>
                                <GeolocationButton onLocationObtained={handleLocationObtained}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SearchedLawFirms onShowPopup={onShowPopup} onShowMore={handleSearch}
                              onZipcodeReturned={handleZipcodeFromSearch}/>
        </div>
    );
}
