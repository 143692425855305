import React, {createContext, useState} from 'react';
import api from '../api';

export const SearchContext = createContext();

export const SearchProvider = ({children}) => {
    const [searchSended, setSearchSended] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [cityForTitle, setCityForTitle] = useState();
    const [companiesForTitle, setCompaniesForTitle] = useState();
    const [searchResults, setSearchResults] = useState([]);
    const [lawFirmResults, setLawFirmResults] = useState([]);
    const [vendorResults, setVendorResults] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [lawFirmPage, setLawFirmPage] = useState(1);
    const [vendorPage, setVendorPage] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [lawFirmTotalResults, setLawFirmTotalResults] = useState(0);
    const [vendorTotalResults, setVendorTotalResults] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [lawFirmTotalPages, setLawFirmTotalPages] = useState(1);
    const [vendorTotalPages, setVendorTotalPages] = useState(1);
    const [specialization, setSpecialization] = useState(null);
    const [problem, setProblem] = useState(null);
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [zipCode, setZipCode] = useState(null);
    const [language, setLanguage] = useState([]);
    const [freeConsultation, setFreeConsultation] = useState(null);
    const [foundationYears, setFoundationYears] = useState([]);
    const [suggestion, setSuggestion] = useState('');
    const [testCount, setTestCount] = useState(22);
    const [bannerHeight, setBannerHeight] = useState(0); // Новое состояние для высоты баннера
    const [showAdvanced, setShowAdvanced] = useState(false); // Добавляем состояние showAdvanced
    const [searchSource, setSearchSource] = useState(''); // Хранит источник поиска


    const calculateTotalPages = (count, pageSize) => {
        return Math.ceil(count / pageSize);

    };

    const updateSearchResults = ({results, count, page, suggestion}) => {
        console.log('Updating search results:', results, 'count:', count, 'page:', page, 'suggestion:', suggestion);

        const pageSize = 7;
        const calculatedTotalPages = calculateTotalPages(count, pageSize);

        setSearchResults(prevResults => {
            // Изменение: Используем старые и новые результаты
            const newResults = page === 1 ? results : [...prevResults, ...results];
            console.log('Previous results:', prevResults);
            console.log('New results:', results);
            console.log('Updated searchResults:', newResults);
            return newResults;
        });

        setCurrentPage(page);
        setTotalResults(count);
        setTotalPages(calculatedTotalPages);
        setSuggestion(suggestion);

        console.log('Total results set to:', count);
        console.log('Total pages set to:', calculatedTotalPages);
    };


    const updateLawFirmResults = ({results, count, page, suggestion}) => {
        console.log('Updating law firm results:', results, 'count:', count, 'page:', page, 'suggestion:', suggestion);

        const pageSize = 7;
        const calculatedTotalPages = calculateTotalPages(count, pageSize);

        setLawFirmResults(prevResults => {
            // Убираем фильтрацию по ID и просто объединяем предыдущие и новые результаты
            const updatedResults = page === 1 ? results : [...prevResults, ...results];
            console.log('Previous results:', prevResults);
            console.log('New results:', results);
            console.log('Updated lawFirmResults:', updatedResults);
            return updatedResults;
        });

        setLawFirmPage(page);
        setLawFirmTotalResults(count);
        setLawFirmTotalPages(calculatedTotalPages);
        setSuggestion(suggestion);

        console.log('Law firm total results set to:', count);
        console.log('Law firm total pages set to:', calculatedTotalPages);
    };

    const updateVendorResults = ({results, count, page, suggestion}) => {
        console.log('Updating vendor results:', results, 'count:', count, 'page:', page, 'suggestion:', suggestion);
        const pageSize = 7;
        const calculatedTotalPages = calculateTotalPages(count, pageSize);

        setVendorResults(prevResults => {
            const newResults = page === 1 ? results : [...prevResults, ...results];
            console.log('Previous results:', prevResults);
            console.log('New results:', results);
            console.log('Updated searchResults:', newResults);
            return newResults;
        });
        setVendorPage(page);
        setVendorTotalResults(count);
        setVendorTotalPages(calculatedTotalPages);
        setSuggestion(suggestion);
        console.log('Vendor total results set to:', count);
        console.log('Vendor total pages set to:', calculatedTotalPages);
    };

    const fetchMoreCompanies = async (page) => {
        setLoadingMore(true);
        try {
            const requestData = {
                specialization,
                problem,
                cities: cities.map(city => city.city_id),
                states: states.map(state => state.state_id),
                zip_code: zipCode,
                language,
                free_consultation: freeConsultation,
                foundation_years: foundationYears,
                page
            };
            console.log('Sending show more request with data:', requestData);

            const response = await api.post(`/get-filtered-specialization/`, requestData);
            const {results, count} = response.data;
            console.log('Response from fetchMoreCompanies:', response.data);
            updateSearchResults({results, count, page});
        } catch (error) {
            console.error('Error fetching more companies:', error);
        } finally {
            setLoadingMore(false);
        }
    };

    const fetchMoreLawFirms = async (page) => {
        setLoadingMore(true);
        try {
            const requestData = {
                specialization,
                cities: cities.map(city => city.city_id),
                states: states.map(state => state.state_id),
                zip_code: zipCode,
                language,
                free_consultation: freeConsultation,
                foundation_years: foundationYears,
                page
            };
            console.log('Sending show more request to /law-firms/ with data:', requestData);

            const response = await api.post(`/law-firms/`, requestData);
            const {results, count} = response.data;
            console.log('Response from fetchMoreLawFirms:', response.data);
            updateLawFirmResults({results, count, page});
        } catch (error) {
            console.error('Error fetching more law firms:', error);
        } finally {
            setLoadingMore(false);
        }
    };


    const fetchMoreVendors = async (page) => {
        setLoadingMore(true);
        try {
            const requestData = {
                specialization,
                cities: cities.map(city => city.city_id),
                states: states.map(state => state.state_id),
                zip_code: zipCode,
                language,
                free_consultation: freeConsultation,
                foundation_years: foundationYears,
                page
            };
            console.log('Sending show more request to /vendors/ with data:', requestData);

            const response = await api.post(`/vendors-searching/`, requestData);
            const {results, count} = response.data;
            console.log('Response from fetchMoreVendors:', response.data);
            updateVendorResults({results, count, page});
        } catch (error) {
            console.error('Error fetching more vendors:', error);
        } finally {
            setLoadingMore(false);
        }
    };

    const openPopup = (companyId) => {
        const company = searchResults.find(c => c.id === companyId) || lawFirmResults.find(c => c.id === companyId) || vendorResults.find(c => c.id === companyId);
        setSelectedCompany(company);
        console.log('Popup opened for company:', company);
    };

    const closePopup = () => {
        setSelectedCompany(null);
        console.log('Popup closed');
    };

    const changeSearchSended = () => setSearchSended(true);
    const changeCityForTitle = (data) => setCityForTitle(data);
    const changeCompaniesForTitle = (data) => setCompaniesForTitle(data);

    return (
        <SearchContext.Provider value={{
            searchResults,
            setSearchResults: updateSearchResults,
            lawFirmResults,
            setLawFirmResults: updateLawFirmResults,
            vendorResults,
            setVendorResults: updateVendorResults,
            fetchMoreCompanies,
            fetchMoreLawFirms,
            fetchMoreVendors,
            openPopup,
            closePopup,
            selectedCompany,
            searchSended,
            changeSearchSended,
            loading,
            setLoading,
            loadingMore,
            cityForTitle,
            changeCityForTitle,
            companiesForTitle,
            changeCompaniesForTitle,
            currentPage,
            setCurrentPage,
            lawFirmPage,
            setLawFirmPage,
            vendorPage,
            setVendorPage,
            totalResults,
            lawFirmTotalResults,
            vendorTotalResults,
            totalPages,
            lawFirmTotalPages,
            vendorTotalPages,
            specialization,
            setSpecialization,
            problem,
            setProblem,
            cities,
            setCities,
            states,
            setStates,
            zipCode,
            setZipCode,
            language,
            setLanguage,
            freeConsultation,
            setFreeConsultation,
            foundationYears,
            setFoundationYears,
            suggestion,
            testCount,
            bannerHeight,
            setBannerHeight,
            showAdvanced,
            setShowAdvanced,
            searchSource,
            setSearchSource,
            setSearchSended,
        }}>
            {children}
        </SearchContext.Provider>
    );
};
