import React, { useCallback, useEffect, useState } from 'react';
import VendorWithImage from './VendorWithImage';
import LawCompanyAdvertise from '../LawCompanyAdvertise';
import api from '../../api';

const VariousVendorCards = React.memo(({ vendors, onShowPopup }) => {
    const [advertise, setAdvertise] = useState(null);
    const [loading, setLoading] = useState(true);
    const vendorsPerPage = 7; // Количество поставщиков на страницу

    useEffect(() => {
        const fetchAdvertise = async () => {
            try {
                const response = await api.get('/advertise/');
                setAdvertise(response.data);
            } catch (error) {
                console.error('Error fetching the advertise data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAdvertise();
    }, []);

    const getPaginatedVendors = useCallback(() => {
        if (!vendors || vendors.length === 0) {
            return [];
        }

        const paginatedVendors = [];
        for (let i = 0; i < vendors.length; i += vendorsPerPage) {
            const page = vendors.slice(i, i + vendorsPerPage);
            paginatedVendors.push(page);
        }
        return paginatedVendors;
    }, [vendors]);

    const paginatedVendors = getPaginatedVendors();

    return (
        <>
            {paginatedVendors.map((page, pageIndex) => (
                <React.Fragment key={pageIndex}>
                    {page.map(vendor => {
                        const hasActivePlans = vendor.active_plans && vendor.active_plans.length > 0;
                        const styleClass = hasActivePlans ? 'vendor-day' : (Math.random() > 0.5 ? 'vendor-verified' : '');
                        return (
                            <VendorWithImage
                                key={vendor.vendor_id}
                                vendor={vendor}
                                onShowPopup={onShowPopup}
                                styleClass={styleClass}
                            />
                        );
                    })}
                    {!loading && advertise && <LawCompanyAdvertise key={`ad-${pageIndex}`} advertise={advertise} />}
                </React.Fragment>
            ))}
        </>
    );
});

export default VariousVendorCards;
