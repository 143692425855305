import React, { useState, useEffect } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import api from '../../api';
import './style.css';

const MultiSelectSocialMedia = ({ label, onChange }) => {
    const [platforms, setPlatforms] = useState([]);
    const [selectedPlatforms, setSelectedPlatforms] = useState([]);

    useEffect(() => {
        const fetchPlatforms = async () => {
            try {
                const response = await api.get('/platforms/');
                setPlatforms(response.data);
            } catch (error) {
                console.error('Error fetching platforms:', error);
            }
        };

        fetchPlatforms();
    }, []);

    useEffect(() => {
        if (onChange) {
            onChange(selectedPlatforms);
        }
    }, [selectedPlatforms, onChange]);

    return (
        <div className="column">
            <label className="label">{label}</label>
            <MultiSelect
                value={selectedPlatforms}
                options={platforms}
                onChange={(e) => setSelectedPlatforms(e.value)}
                optionLabel="name"
                display="chip"
                filter
                className=""
                placeholder=""
            />
        </div>
    );
};

export default MultiSelectSocialMedia;
