import React, {useEffect, useState} from 'react';
import api from "../../api";

const VendorWithImage = ({vendor, onShowPopup, styleClass}) => {
    // if (!vendor) {
    //     console.error('VendorWithImage received no vendor data');
    //     return null;
    // }

    const truncateDescription = (desc, length) => {
        if (desc && desc.length > length) {
            return desc.substring(0, length) + '...';
        }
        return desc;
    };

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [isFavorite, setIsFavorite] = useState(vendor.is_favorite);

    const formatPhoneNumber = (phoneNumber) => {
        let cleaned = ('' + phoneNumber).replace(/\D/g, '');
        if (!cleaned.startsWith('1')) {
            cleaned = '1' + cleaned;
        }
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
        return phoneNumber;
    };

    const formattedAddress = vendor.address
        ? `${vendor.address.street_address}, ${vendor.address.city.name}, ${vendor.address.state.name}`
        : 'Address not available';

    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(formattedAddress)}`;
    const appleMapsUrl = `http://maps.apple.com/?q=${encodeURIComponent(formattedAddress)}`;
    useEffect(() => {
        const checkFavoriteStatus = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const config = {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                };
                const response = await api.get(`/check-favorite-vendor/${vendor.vendor_id}/`, config);
                setIsFavorite(response.data.is_favorite);
            } catch (error) {
                console.error('Error checking favorite status:', error);
            }
        };

        checkFavoriteStatus();
    }, [vendor.vendor_id]);

    const getLogoUrl = (logo) => {

        if (logo && !logo.startsWith('http://') && !logo.startsWith('https://')) {
            const modifiedLogo = `${baseUrl}${logo}`;
            return modifiedLogo;
        }
        return logo;
    };

    const handleAddToFavorites = async () => {
        try {
            const response = await api.post('/add-favorite-vendor/', {vendor_id: vendor.vendor_id});

            if (response.status === 200 || response.status === 201) {
                setIsFavorite(true);
            }
        } catch (error) {
            console.error('Error adding to favorites:', error);
        }
    };

    const handleRemoveFromFavorites = async () => {
        try {
            const response = await api.post('/remove-favorite-vendor/', {vendor_id: vendor.vendor_id});

            if (response.status === 200) {
                setIsFavorite(false);
            }
        } catch (error) {
            console.error('Error removing from favorites:', error);
        }
    };

    const handleToggleFavorites = () => {
        if (isFavorite) {
            handleRemoveFromFavorites();
        } else {
            handleAddToFavorites();
        }
    };

    return (
        <div
            className={`vendor ${styleClass}`}
            onClick={() => onShowPopup(vendor.vendor_id, 'vendor')}
            style={{
                background: styleClass === 'vendor-day'
                    ? `linear-gradient(rgba(44, 65, 87, 0.7), rgba(44, 65, 87, 0.7)), url(${vendor.background_image})`
                    : ``
            }}
        >
            <div className="container">
                <div className="justify">
                    {styleClass === 'vendor-day' && vendor.logo && (
                        <div className="vendor__logo js--show-popup" onClick={() => onShowPopup(vendor.id, 'vendor')}>
                            <picture>
                                <source type="image/webp"
                                        srcSet={`${getLogoUrl(vendor.logo)} 1x, ${getLogoUrl(vendor.logo)} 2x`}/>
                                <source type="image/jpg"
                                        srcSet={`${getLogoUrl(vendor.logo)} 1x, ${getLogoUrl(vendor.logo)} 2x`}/>
                                <img srcSet={`${getLogoUrl(vendor.logo)} 1x, ${getLogoUrl(vendor.logo)} 2x`}
                                     alt={vendor.name}/>
                            </picture>

                        </div>
                    )}
                    <div className="vendor__data js--show-popup" onClick={() => onShowPopup(vendor.id, 'company')}>
                        <div className="vendor__header">
                            <h4 className="vendor__name">{vendor.name}</h4>
                            {styleClass === 'vendor-verified' && (
                                <div className="verified">
                                    <img src="assets/icon--verified-CxOX3a18.svg" alt="Verified"/>
                                </div>
                            )}
                            <button
                                className={`favorites ${isFavorite ? 'added' : ''}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleToggleFavorites(vendor.id);
                                }}
                            >
                                <svg>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M1.58733 3.10072C3.70393 0.940385 7.13582 0.940385 9.25246 3.10072L10.0323 3.89665L10.7475 3.16683C12.8641 1.0065 16.296 1.0065 18.4127 3.16683C20.5293 5.32717 20.5292 8.82998 18.4127 10.9904L10.4695 19.0901C10.4418 19.1184 10.5003 19.068 10.4695 19.0901C10.2068 19.2849 9.79324 19.2991 9.5566 19.0576L1.58757 10.9239C-0.52903 8.76353 -0.529268 5.26111 1.58733 3.10072Z"></path>
                                </svg>
                            </button>
                            {/*{styleClass === 'vendor-day' && renderFreeConsultation()}*/}
                        </div>
                        <p className="vendor__description">{truncateDescription(vendor.description, 150)}</p>
                    </div>
                    <div className="vendor__contacts">
                        <div className="row">
                            <a className="vendor__link" href={googleMapsUrl} target="_blank"
                               rel="noopener noreferrer">{formattedAddress}</a>
                            <span className="vendor__label">Address</span>
                        </div>
                        <div className="row">
                            <a className="vendor__link"
                               href={`tel:${vendor.phone_number}`}>{formatPhoneNumber(vendor.phone_number)}</a>
                            <span className="vendor__label">Phone</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VendorWithImage;
