import React, {useState} from 'react';
import InputWithLabel from '../Form/Input/InputWithLabel';

const EditWorkersPopup = ({show, onClose, onSave, initialData}) => {
    // const [formData, setFormData] = useState({
    //     workers: initialData?.workers || []  // Изначально список сотрудников из данных
    // });
    const [formData, setFormData] = useState(() => ({
        workers: initialData?.workers?.map(worker => ({
            ...worker,
            specializations: worker.specializations?.length ? worker.specializations : [{name: ''}],  // Инициализируем специализации как массив
            positions: worker.positions?.length ? worker.positions : [{title: ''}]  // Инициализируем должности как массив
        })) || []
    }));

    console.log('Initial data:', initialData);

    const getWorkerImageUrl = (image) => {
        if (image instanceof File) {
            return URL.createObjectURL(image);
        }
        return image || '/assets/worker-default.png';  // Изображение по умолчанию
    };

    const handleWorkerChange = (index, name, value) => {
        const updatedWorkers = formData.workers.map((worker, i) => {
            if (i === index) {
                if (name === 'specialization') {
                    // Обновляем массив специализаций, оставляя все кроме первого элемента, и заменяем первый
                    return {
                        ...worker,
                        specializations: [{name: value}]
                    };
                }
                if (name === 'position') {
                    // Обновляем массив позиций, оставляя все кроме первого элемента, и заменяем первый
                    return {
                        ...worker,
                        positions: [{title: value}]
                    };
                }
                // Обновляем другие поля
                return {...worker, [name]: value};
            }
            return worker;
        });

        setFormData((prevData) => ({
            ...prevData,
            workers: updatedWorkers
        }));
    };


    const handleImageChange = (index, e) => {
        const file = e.target.files[0];
        if (file) {
            const updatedWorkers = formData.workers.map((worker, i) => {
                if (i === index) {
                    return {...worker, photo: file};  // Сохраняем файл изображения сотрудника
                }
                return worker;
            });

            setFormData((prevData) => ({
                ...prevData,
                workers: updatedWorkers
            }));
        }
    };

    const handleAddWorker = () => {
        setFormData((prevData) => ({
            ...prevData,
            workers: [...prevData.workers, {
                id: null,
                first_name: '',
                last_name: '',
                specialization: '',
                position: '',
                photo: ''
            }]
        }));
    };

    const handleDeleteWorker = (index) => {
        const updatedWorkers = formData.workers.filter((_, i) => i !== index);
        setFormData((prevData) => ({
            ...prevData,
            workers: updatedWorkers
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        let hasValidWorker = false;  // Флаг для проверки наличия хотя бы одного заполненного сотрудника

        for (let index = 0; index < formData.workers.length; index++) {
            const worker = formData.workers[index];

            if (worker.first_name.trim() === '' && worker.last_name.trim() === '') continue;  // Пропуск пустых сотрудников

            if (worker.id) {
                formDataToSend.append(`workers[${index}][id]`, worker.id);
            }
            formDataToSend.append(`workers[${index}][first_name]`, worker.first_name);
            formDataToSend.append(`workers[${index}][last_name]`, worker.last_name);

            // Отправляем specialization как строку, а не массив
            if (worker.specializations && worker.specializations.length > 0) {
                formDataToSend.append(`workers[${index}][specialization]`, worker.specializations[0].name);
            }

            // Отправляем position как строку, а не массив
            if (worker.positions && worker.positions.length > 0) {
                formDataToSend.append(`workers[${index}][position]`, worker.positions[0].title);
            }

            if (worker.photo instanceof File) {
                formDataToSend.append(`workers[${index}][photo]`, worker.photo);
            } else if (!worker.id && !worker.photo) {
                // Если работник новый и нет изображения, загружаем изображение по умолчанию
                const response = await fetch('/assets/worker-default.png');
                const blob = await response.blob();
                const defaultImageFile = new File([blob], 'worker-default.png', {type: 'image/png'});
                formDataToSend.append(`workers[${index}][photo]`, defaultImageFile);
            }

            hasValidWorker = true;  // Найден валидный сотрудник
        }

        if (hasValidWorker) {
            await onSave(formDataToSend);  // Передаем данные только если есть хотя бы один валидный сотрудник
        } else {
            console.error("No valid workers to save");
        }
    };


    if (!show) return null;

    // Функция для рендеринга инпутов
    const renderInputWithLabel = (index, field, label) => {
        let fieldValue = '';

        // Выводим информацию о работнике и поле в консоль для диагностики
        console.log(`Rendering field: ${field}, Index: ${index}, Worker:`, formData.workers[index]);

        // Проверяем, массив ли это и есть ли в нем значения
        if (field === 'specialization' && Array.isArray(formData.workers[index].specializations)) {
            fieldValue = formData.workers[index].specializations[0]?.name || '';  // Используем 'specializations'
        } else if (field === 'position' && Array.isArray(formData.workers[index].positions)) {
            fieldValue = formData.workers[index].positions[0]?.title || '';  // Используем 'positions'
        } else {
            // Для других полей (например, first_name и last_name) просто берем значение
            fieldValue = formData.workers[index][field] || '';
        }

        console.log(`Field value for ${field}:`, fieldValue);

        return (
            <InputWithLabel
                name={field}
                value={fieldValue}  // Проверяем на null или undefined
                onChange={(e) => handleWorkerChange(index, field, e.target.value)}
                label={label}
            />
        );
    };


    return (
        <div className="modal visible" id="edit-workers">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                <button onClick={onClose} className="button no-style close js--close-modal">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleSubmit}>
                        <h2>Edit Workers</h2>
                        {formData.workers.map((worker, index) => (
                            <React.Fragment key={index}>
                                {renderInputWithLabel(index, 'first_name', 'First Name')}
                                {renderInputWithLabel(index, 'last_name', 'Last Name')}
                                {renderInputWithLabel(index, 'specialization', 'Specialization')}
                                {renderInputWithLabel(index, 'position', 'Position')}

                                {/* Компонент для загрузки изображения */}
                                <div className="profile__logo">
                                    <picture>
                                        <img src={getWorkerImageUrl(worker.photo)}
                                             alt={`${worker.first_name} ${worker.last_name} image`}/>
                                    </picture>
                                    <label className="userpick__edit">
                                        <img src="/assets/icon--edit-bg-BVfPEI-f.svg" alt="Edit"/>
                                        <input type="file" onChange={(e) => handleImageChange(index, e)}/>
                                    </label>
                                </div>

                                <button
                                    className="button no-style"
                                    type="button"
                                    onClick={() => handleDeleteWorker(index)}
                                >
                                    Delete
                                </button>
                            </React.Fragment>
                        ))}

                        <button
                            className="button button-border"
                            type="button"
                            onClick={handleAddWorker}
                        >
                            Add New Worker
                        </button>
                        <button className="button button-black" type="submit">Save Workers</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditWorkersPopup;
