import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';

const AppWrapper = () => (
  <Routes>
    <Route path="*" element={<App />} />
  </Routes>
);

export default AppWrapper;
