import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import api from '../../api';
import SearchForm from '../SearchForm';
import {SearchContext} from '../../context/SearchContext';
import GeolocationButton from '../GeolocationButton';
import fetchCityByIP from '../FetchCityByIP';
import MultiSelectWithDependentCities from "../MultiSelects/MultiSelectWithDependentCities";
import MultiSelectInColumnWithLabel from "../MultiSelects/MultiSelectInColumnWithLabel";
import MultiSelectFoundationYear from "../MultiSelects/MultiSelectFoundationYear";
import {RiveDemo} from "../../utils/animateExample";
import StarsDemo from "../../utils/animateWhiteStar";
import {TextAnimator} from "../../utils/text-animator"; // Импортируем TextAnimator

const HeaderSearch = ({minimizeBanner, minimizeHeader}) => {
    const {setSearchResults, changeSearchSended, suggestion, showAdvanced, setShowAdvanced} = useContext(SearchContext);
    const [specializations, setSpecializations] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState([]);
    const [selectedState, setSelectedState] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);
    const [defaultCity, setDefaultCity] = useState(null);
    const [defaultCityName, setDefaultCityName] = useState(null);
    const [selectedSpecialization, setSelectedSpecialization] = useState('All');
    const [userLocation, setUserLocation] = useState(null);
    const [zipCode, setZipCode] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [isAllSpecializationActive, setIsAllSpecializationActive] = useState(true);
    const [isConsultationCheck, setIsConsultationCheck] = useState(false);
    const [selectedFoundationYears, setSelectedFoundationYears] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [minWidth, setMinWidth] = useState(null);
    const textRef = useRef(null);
    const [animator, setAnimator] = useState(null);

    useEffect(() => {
        const fetchSpecializations = async () => {
            try {
                const response = await api.get('/specializations/');
                setSpecializations(response.data);
            } catch (error) {
                console.error('Error fetching specializations:', error);
            }
        };

        const fetchLanguages = async () => {
            try {
                const response = await api.get('/languages/');
                const uniqueLanguages = Array.from(new Set(response.data.map(lang => lang.name)))
                    .map(name => response.data.find(lang => lang.name === name));
                setLanguages(uniqueLanguages);
                console.log('Fetched unique languages:', uniqueLanguages);
            } catch (error) {
                console.error('Error fetching languages:', error);
            }
        };

        fetchSpecializations();
        fetchLanguages();
    }, []);

    useEffect(() => {
        const getCityFromIP = async () => {
            const city = await fetchCityByIP();
            if (city) {
                setDefaultCityName(city);
                setSelectedCity([city]);
            }
        };

        if (!userLocation && !zipCode) {
            getCityFromIP();
        }
    }, [userLocation, zipCode]);

    useEffect(() => {
        if (textRef.current) {
            const animatorInstance = new TextAnimator(textRef.current);
            setAnimator(animatorInstance);
            animatorInstance.animate(); // Запускаем анимацию один раз при появлении элемента

            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    setMinWidth(entry.contentRect.width);
                }
            });

            resizeObserver.observe(textRef.current);

            return () => resizeObserver.disconnect();
        }
    }, [suggestion]);


    const toggleAdvancedSearch = () => {
        setShowAdvanced(!showAdvanced);
        minimizeBanner();
        minimizeHeader();
    };

    const handleSearch = (data) => {
    // Закрываем уведомление перед новым поиском
    setShowNotification(false);

    // Небольшая задержка, чтобы закрытие произошло до повторного открытия
    setTimeout(() => {
        setSearchResults(data);
        changeSearchSended();
        setShowAdvanced(false);
        minimizeBanner();
        minimizeHeader();

        // Открываем уведомление снова после всех изменений
        setShowNotification(true);
    }, 100); // Задержка в 300 мс может быть настроена по вашему усмотрению
};

    const handleCloseNotification = () => {
        setShowNotification(false);
    };

    const handleLocationObtained = (zip, location) => {
        setUserLocation(location);
        setZipCode(zip);
        minimizeBanner();
        minimizeHeader();
    };

    const handleSpecializationClick = (spec) => {
        setSelectedSpecialization(spec);
        setIsAllSpecializationActive(spec === 'All');
        minimizeBanner();
        minimizeHeader();
    };

    const handleSpecializationChange = (spec) => {
        setSelectedSpecialization(spec);
        setIsAllSpecializationActive(spec === 'All');
        minimizeBanner();
        minimizeHeader();
    };

    const handleConsultationClick = useCallback(() => {
        setIsConsultationCheck(!isConsultationCheck);
    }, [isConsultationCheck]);

    const onCityStateChange = (cities, states) => {
        setSelectedCity(cities);
        setSelectedState(states);
        console.log('Selected states in HeaderSearch:', states);
        console.log('Selected cities in HeaderSearch:', cities);
    };

    const onSelect = useCallback(() => {
        console.log('select');
    }, []);

    const onRemove = useCallback(() => {
        console.log('remove');
    }, []);

    return (
        <div className="header__search">
            <div className="container">
                <div className="content">
                    {showNotification && (
                        <div className='search__notification' onClick={handleCloseNotification}>
                            <StarsDemo/>
                            <p
                                ref={textRef}
                                style={{minWidth: minWidth ? `${minWidth}px` : 'auto'}}
                            >
                                {suggestion}
                            </p>
                            <button>
                                <img src="assets/icon--cross.svg" alt="Close Icon"/>
                            </button>
                        </div>
                    )}
                    <SearchForm
                        onSearch={handleSearch}
                        cities={[...(defaultCity ? [defaultCity] : []), ...selectedCity]}
                        cityName={defaultCityName}
                        states={selectedState}
                        zipCode={zipCode}
                        specialization={selectedSpecialization !== 'All' ? selectedSpecialization : ''}
                        onSpecializationChange={handleSpecializationChange}
                        languages={selectedLanguage}
                        isAllSpecializationActive={isAllSpecializationActive}
                        freeConsultation={isConsultationCheck}
                        foundationYears={selectedFoundationYears}
                        minimizeHeader={minimizeHeader}
                        minimizeBanner={minimizeBanner}
                    />
                    <div className="search__filters">
                        <div className="tabs-scroll-wrap">
                            <ul className="search__tabs js--search-tab">
                                <li key="all" className={isAllSpecializationActive ? 'active' : ''}
                                    onClick={() => handleSpecializationClick('All')} data-text="All text">
                                    <RiveDemo/>
                                </li>
                                {specializations.map((spec) => (
                                    <li key={spec.specialization_id}
                                        className={`${selectedSpecialization === spec.name ? 'active' : ''}`}
                                        onClick={() => handleSpecializationClick(spec.name)}
                                        data-text={`${spec.name} text`}>{spec.name}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="search__side">
                            <button className={"search__adv" + (showAdvanced ? ' open' : '')}
                                    onClick={toggleAdvancedSearch}>
                                <div className='points'>
                                    <div className='point point1'></div>
                                    <div className='point point2'></div>
                                    <div className='point point3'></div>
                                    <div className='point point4'></div>
                                </div>
                                Advanced Search
                            </button>
                            <GeolocationButton onLocationObtained={handleLocationObtained}/>
                        </div>
                    </div>
                    <div className={`search__advanced  ${showAdvanced ? 'show' : ''}`}>
                        <div className='grid-container'>
                            <MultiSelectWithDependentCities
                                label="City"
                                onChange={onCityStateChange}
                                states={selectedState}
                                placeholder="Select city"
                            />
                            <MultiSelectInColumnWithLabel
                                label="Language"
                                options={languages.map(lang => ({name: lang.name, code: lang.name}))}
                                onChange={setSelectedLanguage}
                                placeholder=""
                            />
                            <MultiSelectFoundationYear
                                label="Foundation Year"
                                onChange={setSelectedFoundationYears}
                            />
                        </div>
                        <div className='header__search__buttons'>
                            <div className='column desktop'>
                                <label className="label">Consultations</label>
                                <label onClick={handleConsultationClick}
                                       className={isConsultationCheck ? 'consultations-checkbox checked' : 'consultations-checkbox'}>
                                    <span>All</span>
                                    <span>Free</span>
                                </label>
                            </div>
                            <div className='column mobile'>
                                <label className="label">&nbsp;</label>
                                <div className='search__radius'>
                                    <span>Free Consultation</span>
                                    <label
                                        onClick={handleConsultationClick}
                                        className={isConsultationCheck ? 'toggle checked' : 'toggle'}>
                                        <div className='toggle__container'>
                                            <div className='toggle__circle'></div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderSearch;
