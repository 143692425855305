import React, { useState, useCallback, useEffect } from 'react';

export default function InputWithButtonCopy({ value }) {
    const [inputValue, setInputValue] = useState(value);
    const [showCopied, setShowCopied] = useState(false);

    const handleChange = useCallback((e) => {
        setInputValue(e.target.value);
    }, []);

    const handleClick = useCallback(() => {
        navigator.clipboard.writeText(inputValue);
        setShowCopied(true);
        setTimeout(hideCopied, 2000)
    }, [inputValue]);

    const hideCopied = useCallback(() => setShowCopied(false))

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    return (
        <label className="input-label button-inside">
            <input
                onChange={handleChange}
                className="input"
                type="text"
                value={inputValue}
            />
            <button type='button' className='button button-black' onClick={handleClick}>Copy</button>

            {showCopied ? <span className='copied'>Сopied</span> : ""}
        </label>
    );
}
