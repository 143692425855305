import React from 'react';
import { useRive, Layout, Fit, Alignment } from "@rive-app/react-canvas";

export const StarsDemo = () => {
    const { RiveComponent } = useRive({
        // Load a local riv `stars_w.riv`
        src: "./assets/stars_w.riv",
        // This is optional. Provides additional layout control.
        layout: new Layout({
            fit: Fit.FitWidth, // Change to: rive.Fit.Contain, or Cover
            alignment: Alignment.Center,
        }),
        autoplay: true,
    });

    return <RiveComponent />;
};

export default StarsDemo;
