import React, { useState, useCallback, useEffect } from 'react';
import InputWithLabel from "../Form/Input/InputWithLabel";
import TextareaWithLabel from "../Form/Input/TextareaWithLabel";
import api from '../../api';

export default function FeedbackPopup({ show, showSendOkPopup, closeFeedbackPopup }) {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        about: ''
    });
    const [template, setTemplate] = useState(null);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) {
            newErrors.name = 'Name is required';
        }

        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email address is invalid';
        }

        if (!formData.phoneNumber) {
            newErrors.phoneNumber = 'Phone Number is required';
        } else if (!/^\d{10,15}$/.test(formData.phoneNumber)) {
            newErrors.phoneNumber = 'Phone Number is invalid';
        }

        if (!formData.about) {
            newErrors.about = 'About is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const fetchTemplate = async (templateId) => {
        try {
            const response = await api.get(`/email-template/${templateId}/`);
            setTemplate(response.data);
        } catch (error) {
            console.error('Error fetching template:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        if (!template) {
            console.error('Template not loaded');
            return;
        }

        console.log('Form data:', formData);

        const emailData = {
            subject: template.subject_template.replace('{{name}}', formData.name),
            message: template.message_template
                .replace('{{name}}', formData.name)
                .replace('{{email}}', formData.email)
                .replace('{{phoneNumber}}', formData.phoneNumber)
                .replace('{{about}}', formData.about.replace(/\n/g, '<br>')), // Замена \n на <br>
            recipient: template.recipient,
            isHtml: true // Указываем, что сообщение будет в формате HTML
        };

        console.log('Email data to send:', emailData);

        try {
            const response = await api.post('/send-email/', emailData);
            if (response.status === 200) {
                showSendOkPopup();
            } else {
                console.error('Failed to send email', response.data);
            }
        } catch (error) {
            console.error('Error sending email', error);
        }
    };

    const handleClose = useCallback(() => {
        closeFeedbackPopup();
    }, [closeFeedbackPopup]);

    useEffect(() => {
        const templateId = 1;  // Замените на нужный ID шаблона
        fetchTemplate(templateId);
    }, []);

    return (
        <>
            {show && (
                <div className="modal visible">
                    <div className="modal__overlay" onClick={handleClose}></div>
                    <div className="modal__content">
                        <button onClick={handleClose} className="button no-style close js--close-modal">
                            <img src="/assets/icon--close-CN6QlgAA.svg" alt="" />
                        </button>
                        <div className="modal__form">
                            <form className="content" onSubmit={handleSubmit}>
                                <h2>Assist</h2>
                                <InputWithLabel
                                    label='Name'  // Добавлен текст для пользователя
                                    name='name'
                                    value={formData.name}
                                    onChange={handleChange}
                                    error={errors.name}
                                />
                                <InputWithLabel
                                    label='E-mail'  // Добавлен текст для пользователя
                                    name='email'
                                    value={formData.email}
                                    onChange={handleChange}
                                    type='email'
                                    error={errors.email}
                                />
                                <InputWithLabel
                                    label='Phone Number'  // Добавлен текст для пользователя
                                    name='phoneNumber'
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    type='tel'
                                    error={errors.phoneNumber}
                                />
                                <span>Your Question:</span>
                                <TextareaWithLabel
                                    label='About'  // Добавлен текст для пользователя
                                    name='about'
                                    value={formData.about}
                                    onChange={handleChange}
                                    error={errors.about}
                                />
                                <button type='submit' className="button button-black">Send</button>
                                <span>We will contact you soon and clarify the details</span>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
