import React, {createContext, useContext, useState, useEffect, useCallback} from 'react';
import api from '../api';  // Импортируйте ваш API модуль

// Создаем контекст пользователя
const UserContext = createContext();

// Провайдер контекста
export const UserProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [authTokens, setAuthTokens] = useState(() => {
        const accessToken = localStorage.getItem('access_token');
        const refreshToken = localStorage.getItem('refresh_token');
        console.log("Loaded access token from localStorage:", accessToken);  // Логирование токена доступа
        console.log("Loaded refresh token from localStorage:", refreshToken);  // Логирование рефреш токена
        return accessToken && refreshToken ? {access: accessToken, refresh: refreshToken} : null;
    });

    // Функция для обновления токена
    const refreshToken = useCallback(async () => {
        try {
            console.log('Sending refresh request with token:', authTokens?.refresh); // Логирование токена
            const response = await api.post('/token/refresh/', {
                refresh: authTokens?.refresh
            });
            console.log('Refresh response:', response.data); // Логирование успешного ответа
            const newTokens = {
                access: response.data.access,
                refresh: authTokens.refresh,
            };
            setAuthTokens(newTokens);
            localStorage.setItem('access_token', newTokens.access);
            localStorage.setItem('refresh_token', newTokens.refresh);
            console.log('Updated tokens in localStorage:', newTokens);  // Логирование обновленных токенов
            return newTokens.access;
        } catch (error) {
            console.error("Failed to refresh token:", error.response?.status, error.response?.data);
            setAuthTokens(null);
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            return null;
        }
    }, [authTokens]);

    useEffect(() => {
        console.log("Fetching user data...");  // Логирование вызова useEffect
        const fetchUserData = async () => {
            try {
                if (!authTokens) {
                    console.log("No auth tokens available.");
                    return;
                }
                const response = await api.get('/user/', {
                    headers: {
                        Authorization: `Bearer ${authTokens.access}`
                    }
                });
                setUser(response.data);
            } catch (error) {
                if (error.response?.status === 401) {
                    console.log("Access token expired. Trying to refresh...");
                    const newAccessToken = await refreshToken();
                    if (newAccessToken) {
                        const response = await api.get('/user/', {
                            headers: {
                                Authorization: `Bearer ${newAccessToken}`
                            }
                        });
                        setUser(response.data);
                        window.location.reload();
                    } else {
                        setUser(null);
                    }
                } else {
                    console.error('Error fetching user data:', error);
                    setUser(null);
                }
            }
        };

        fetchUserData();
    }, [authTokens, refreshToken]);

    return (
        <UserContext.Provider value={{user, setUser, authTokens, setAuthTokens, refreshToken}}>
            {children}
        </UserContext.Provider>
    );
};

// Хук для использования контекста пользователя
export const useUser = () => {
    return useContext(UserContext);
};
