const fetchCityByIP = async () => {
    try {
        const response = await fetch(`https://api.ipdata.co?api-key=0a32b2524f130a43822692d17a44af3ee1db38cc72b510a7ba6511ea`);
        const data = await response.json();
        if (data && data.city) {
            console.log('City from IP:', data.city);
            return data.city;
        } else {
            console.error('Error fetching city by IP:', data);
            return null;
        }
    } catch (error) {
        console.error('Error fetching city by IP:', error);
        return null;
    }
};

export default fetchCityByIP;