import React, { useEffect, useState } from 'react';
import { MultiSelect } from "primereact/multiselect";
import './style.css';

const MultiSelectFoundationYear = ({ label, onChange }) => {
    const categories = [
        { name: '0-5 years', range: [0, 5] },
        { name: '5-10 years', range: [5, 10] },
        { name: '10-20 years', range: [10, 20] },
        { name: '20+ years', range: [20, Number.MAX_VALUE] }
    ];

    const [selectedCategories, setSelectedCategories] = useState([]);

    useEffect(() => {
        if (onChange) {
            onChange(selectedCategories);
        }
    }, [selectedCategories, onChange]);

    return (
        <div className="column">
            <label className="label">{label}</label>
            <MultiSelect
                value={selectedCategories}
                onChange={(e) => setSelectedCategories(e.value)}
                options={categories}
                optionLabel='name'
                display="chip"
                className=""
            />
        </div>
    );
};

export default MultiSelectFoundationYear;