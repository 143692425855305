import React from 'react';
import './pagination.css';

export default function Pagination({ currentPage, totalPages, onPageChange, scrollToHeader }) {
    const handlePrevPage = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
            scrollToHeader();
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
            scrollToHeader();
        }
    };

    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <li key={i} className={`pagination__el ${i === currentPage ? 'active' : ''}`}>
                    <button onClick={() => onPageChange(i)}>{i}</button>
                </li>
            );
        }
        return pages;
    };

    return (
        <div className='pagination'>
            <button
                className={`pagination__button ${currentPage === 1 ? 'disabled' : ''}`}
                onClick={handlePrevPage}
                disabled={currentPage === 1}
            >
                <div className='icon icon-angle-left'></div>                Prev

            </button>
            <ul className='pagination__list'>
                {renderPageNumbers()}
            </ul>
            <button
                className={`pagination__button ${currentPage === totalPages ? 'disabled' : ''}`}
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
            >
                Next
                <div className='icon icon-angle-right'></div>
            </button>
        </div>
    );
}

