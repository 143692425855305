import React from "react";
import './Footer.css';

export default function Footer () {
    return (
        <div className='profile__block'>
            <div className='container'>
                <footer className='footer'>
                    <div className='footer__social'>
                        <h2>Follow Us in Social Media</h2>
                        <ul className="social">
                            <li>
                                <a href="http://boriskov.com" target="_blank" rel="noopener noreferrer">
                                    <img
                                        src="https://befaster.online/media/platform_icons/5296501_linkedin_network_linkedin_logo_icon_AQTvGWB.png"
                                        alt="LinkedIn"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className='footer__separator'></div>
                    <div className='footer__bottom'>
                        <img src='/assets/logo--footer.svg' className='footer__logo' alt='Logo'/>
                        <span className='footer__copyright'>
                                    &copy;&nbsp;YourCompanyName, 2024 . All rights reserved
                                </span>
                        <nav className='footer__nav'>
                            <ul>
                                <li>
                                    <a href=''>Privacy Policy</a>
                                </li>
                                <li>
                                    <a href=''>Cookie Policy</a>
                                </li>
                                <li>
                                    <a href=''>Accessibility Statement</a>
                                </li>
                                <li>
                                    <a href=''>Support</a>
                                </li>
                                <li>
                                    <a href=''>Do Not Sell My Personal Information</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </footer>
            </div>
        </div>
    )
}